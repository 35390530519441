@import '../../assets/scss/index';
#loginpage {
  padding: 135px 0;
  background: url('../../assets/images/abBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  label {
    font-weight: 600;
    font-size: 14pt;
    margin-bottom: 8px;
  }
  .innerwrap {
    max-width: 400px;
    margin: 0 auto;

    .login-header {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }
  }
  h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 30px;
  }
  button {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    font-family: Inter, Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: #5c5ce5 !important;
    border-radius: 6px;
    margin-top: 25px;
    img {
      margin-right: 8px;
    }
    &:hover {
      background-color: rgba(92, 92, 229, 0.7) !important;
    }
  }
  .auth0-lock.auth0-lock .auth0-lock-center {
    padding: 0;
  }
  .auth0-lock.auth0-lock .auth0-lock-form {
    padding: 0;
  }
  .auth0-lock.auth0-lock
    .auth0-lock-social-button.auth0-lock-social-big-button:nth-child(2) {
    background: #3377ea;
  }
  .auth0-lock-alternative {
    margin-top: 30px !important;
    margin-bottom: 40px !important;
  }
  .auth0-lock.auth0-lock
    .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
    padding-left: 0;
    border: none;
    svg {
      display: none;
    }
  }
  .auth0-lock-social-button.auth0-lock-social-big-button {
    height: 48px;
    border: 1px solid #828282;
    display: flex;
    align-items: center;
  }
  .auth0-lock-social-button-icon {
    height: 48px !important;
  }
  input {
    width: 100%;
    min-width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    height: 48px;
    border: 1px solid #828282;
  }
  .auth0-lock-alternative {
    height: 0 !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-form p {
    height: 1px;
    background: #bdbdbd;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 25px;
    span {
      display: none;
    }
  }
  .loginfeild {
    .loginemail {
      margin-bottom: 25px;
    }
    .loginpass {
      margin-bottom: 32px;
    }
    label {
      font-weight: 600;
      font-size: 14pt;
      margin-bottom: 8px;
    }
  }
  .loginbottom {
    margin-top: 40px;
    text-align: center;
    a {
      font-weight: 500;
      font-size: 14pt;
      margin-bottom: 20px;
      display: inline-block;
      color: #505de5;
    }
    p {
      font-weight: normal;
      font-size: 14pt;
    }
  }
  .auth0-lock.auth0-lock .auth0-lock-header-welcome {
    background: #2f2f60;
    color: #fff !important;
  }
}

.auth0-lock-input {
  min-width: 100% !important;
}
.auth0-lock.auth0-lock .auth0-lock-header-bg {
  background: #2f2f60 !important;
}

.auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
  height: auto;
}

#loginpage {
  .auth0-lock.auth0-lock .auth0-lock-content-wrapper {
    flex-grow: 0;
  }
}
