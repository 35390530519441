@import '../../assets/scss/index';
#reportpage {
  padding: 50px 0;
  background: url('../../assets/images/abBg.png') no-repeat;
  background-size: cover;
  background-position: center;

  .innerwrap {
    max-width: 480px;
    margin: 60px auto !important;
  }
  .reportpage-inner {
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    margin-bottom: 50%;
  }
  .not-found {
    text-align: center;
    font-size: 14pt;
    color: $base-color;
  }
  h2 {
    text-align: center;
    margin-bottom: 2.5rem;
  }
  .divder {
    height: 1px;
    background: #dbdbdb;
    width: 100%;
    margin-left: 0%;
  }
  .repcolor {
    background: #fafafa;
    border-radius: 6px;
  }
  .rep-main {
    padding: 24px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 14pt;
    line-height: 150%;
    p {
      margin-bottom: 0;
      color: #151147;
    }
    span {
      color: #5c5ce5;
      display: flex;
      img {
        margin-left: 15px;
      }
    }
  }
}
.succesmsg.show {
  display: block;
}
.succesmsg {
  position: fixed;
  top: 50px;
  z-index: 99999999;
  background: #eefaef;
  border-radius: 8px;
  right: 0;
  left: 0;
  max-width: 360px;
  margin: 0 auto;
  padding: 16px 24px;
  height: 56px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  display: none;
  p {
    margin-bottom: 0;
    font-size: 14pt;
    line-height: 150%;
    img {
      margin-right: 15px;
    }
  }
}
.reportNotFoundBox {
  display: flex;
  justify-content: center;
  align-items: start;
  height: 44vh;
}
