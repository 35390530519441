@import '../../../../assets/scss/index.scss';

.Toastify__toast {
  width: 100%;
  z-index: 99999999;
  background: #eefaef;
  border-radius: 8px;
  right: 0;
  left: 0;
  max-width: 100% !important;
  margin: 0 auto;
  padding: 16px 24px;
  height: 56px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
}
// div{
//   width: 100%;
// }
.Toastify {
  position: relative;
  z-index: 999999999 !important;
  .Toastify__toast {
    height: 56px !important;
    border-radius: 8px !important;
    color: #0000;
    font-size: 14pt;
    line-height: 150%;
  }
  .Toastify__toast--success {
    background: #eefaef !important;
    padding: 16px 24px !important;
    color: #151147 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02) !important;
    .Toastify__progress-bar--success {
      background: #151147 !important;
    }
    svg {
      color: #238258;
    }
  }
  .Toastify__toast--error {
    background: #f7ebec !important;
    .Toastify__close-button {
      color: #151147 !important;
      margin-top: 10px;
    }
    div {
      color: #151147 !important;
      svg {
        color: #ba2636 !important;
      }
    }
  }
  .Toastify__toast-container {
    max-width: 1200px !important;
    width: 100% !important;
  }
}
.toast {
  button {
    min-width: 20px !important;
  }
}
