@import '../../assets/scss/index.scss';

#about {
  background: url('../../assets/images/abBg.png') no-repeat;
  background-size: cover;
  background-position: center;

  h3{
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  li {
    margin-bottom: 1rem;
  }
}
