@import '../../assets/scss/index';

#verifyEmail {
  padding-top: 130px;
  padding-bottom: 40vh;
  background: url('../../assets/images/abBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    padding-bottom: 10vh;
  }
  .verifyEmail-inner {
    max-width: 560px;
    margin: 0 auto;
    text-align: center;

    p {
      color: #4f4f4f;
      margin: 32px 0;
    }
  }
}
