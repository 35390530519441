@import '../../assets/scss/index';
#Not_foundpage {
  background: $banner-text;
  @include padding(50px, 40px, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(90px, 120px, 0, 0);
  }
  .row {
    @include flex-direction(column);
    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }
  width: 100%;
  p {
    font-size: 14pt;
    color: #4f4f4f;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
    }
  }
  .search-home {
    @include flex-main;
  }

  .half-width-img {
    @include half-width;
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
      padding-left: 12rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }
    img {
      max-width: 270px;
      width: 100%;
      margin-bottom: 20px;
      @include media-breakpoint-down(md) {
        margin-bottom: 0px;
      }
    }
    p {
      font-size: 13px;
      color: #151147;
    }
  }
  .half-width {
    @include half-width;
    position: relative;
    text-align: left;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      text-align: left;
      margin-bottom: 0;
      padding-left: 1rem;
    }
  }
  .steps {
    font-weight: 700;
    color: #5c5ce5;
    font-size: 14px;
  }
  .stepcontant {
    font-size: 14pt;
  }
  h6 {
    font-weight: 600;
    font-size: 14pt;
    color: #4f4f4f;
  }
  .notfoundsearch {
    p {
      margin-top: 20px;
    }
    label {
      font-weight: 600;
      font-size: 14pt;
      margin-bottom: 8px;
      margin-top: 10px;
    }
    .inpit-search.input {
      border: 1px solid #828282;
      box-sizing: border-box;
      border-radius: 6px;
      outline: none !important;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .notfoundUl {
    margin-top: 25px;
    @include media-breakpoint-down(md) {
      margin-top: -10px;
    }
    p {
      margin-bottom: 0;
      font-size: 14pt;
      color: #4f4f4f;
    }
    ul {
      margin-top: 0;
      padding: 0 20px;
      li {
        font-size: 14pt;
        color: #4f4f4f;
      }
    }
  }
}
input.inpit-search.input:focus-visible {
  outline: none;
}
#notfoundsearch {
  background: #343473;
  padding: 15px 0;
  padding-bottom: 5px;
  width: 100%;
  input {
    width: 100%;
    background: #262660;
    border-radius: 6px;
    color: $white;
    border: none;
    height: 46px;
    margin-bottom: 15px;
    padding-left: 40px;
    outline: none;
  }
  .rpsearch {
    position: relative;
    width: 100%;
    img {
      max-width: 20px;
      position: absolute;
      top: 16px;
      left: 10px;
    }
  }
}
#popover-basic {
  min-width: auto;
  margin-left: 12px !important;
  max-width: 1150px;
  width: 100%;
}
