@import '../../assets/scss/index';
#signup {
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 40px 0 0;
  }
  .row {
    @include flex-direction(column);
    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }
  label {
    font-weight: 600;
    font-size: 14pt;
    margin-bottom: 0.25rem;
    color: var(--bs-body-color);
  }
  .auth0-lock-error-msg {
    margin-top: -20px;
    margin-bottom: 0.5rem;
  }
  h1 {
    margin-bottom: 25px;
    font-size: 28px;
  }
  .sign-frm {
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1rem 40px;
    @media (max-width: 768px) {
      padding: 16px;
    }
    h2 {
      font-weight: 600;
      font-size: 14pt;
      color: #000;
    }
    p {
      font-size: 14pt;
      color: #000;
      margin-bottom: 0.25rem;
      // line-height: 0.5rem;
    }
    span {
      font-size: 13px;
      color: #000;
      margin-top: 8px;
      display: inline-block;
      width: 100%;
    }
  }
  .sign-img {
    @media (max-width: 768px) {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 0px;
      border: 1px solid #dbdbdb;
      border-radius: 6px;
      margin-bottom: 10px;
    }
    img {
      max-width: 100%;
      width: 100%;
      @media (max-width: 768px) {
        width: 50%;
        border-radius: 6px;
      }
    }
    .sign-img-contant {
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 55px 40px;
      margin-top: -20px;
      padding-bottom: 30px;
      @media (max-width: 768px) {
        width: 50%;
        margin-top: 0;
        border: none;
        padding: 16px;
        text-align: left;
      }
    }
    p {
      font-size: 14pt;
      color: #000000;
      margin-bottom: 5px;
      @media (max-width: 768px) {
        font-size: 14pt;
      }
    }
    h3 {
      font-weight: 600;
      font-size: 14pt;
      color: #000;
      @media (max-width: 768px) {
        font-size: 14pt;
      }
    }
    .b-img-contant {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        height: 80%;
      }
      p {
        color: #000;
      }
    }
  }
  .sing-f {
    max-width: 530px;
    margin: 55px auto;
    font-size: 14pt;
    color: #000;
    text-align: center;
  }
  .sign-client {
    box-shadow: 0px 0px 16px rgba(47, 47, 96, 0.2);
    border-radius: 6px;
    padding: 20px 35px 30px;
    margin-top: 55px;
    position: relative;
    @media (max-width: 768px) {
      display: none;
    }
    .client-simg {
      margin-top: -80px;
      width: 68px;
      height: 68px;
      border-radius: 50%;
      position: absolute;
      top: 40px;
    }
    .client-img {
      border-radius: 50%;
      height: 70px;
      width: 70px;
    }
    p {
      font-size: 14pt;
      color: #000;
      margin-bottom: 8px;
      margin-top: 16px;
    }
    span {
      font-size: 14pt;
      color: #000;
    }
  }
  .auth0-lock-submit {
    span {
      margin: 0 !important;
      color: #fff;
    }
  }
  button {
    width: 100%;
    font-weight: 600;
    font-size: 14pt;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: #5c5ce5 !important;
    border-radius: 6px;
    color: #fff !important;
    img {
      margin-right: 8px;
    }
  }
  .half-width-img {
    @include half-width;
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
      padding-right: 4rem;
    }
    @media (max-width: 768px) {
      order: 1;
    }
  }
  .half-width {
    @include half-width;
    position: relative;
    text-align: left;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      text-align: left;
      margin-bottom: 0;
      padding-left: 1rem;
    }
    @media (max-width: 768px) {
      order: 2;
    }
  }
  .auth0-lock.auth0-lock .auth0-lock-center {
    padding: 0;
  }
  .auth0-lock.auth0-lock .auth0-lock-form {
    padding: 0;
  }
  .auth0-lock.auth0-lock
    .auth0-lock-social-button.auth0-lock-social-big-button:nth-child(2) {
    background: #3377ea;
  }
  .auth0-lock-alternative {
    margin-top: 30px !important;
    margin-bottom: 40px !important;
  }
  .auth0-lock.auth0-lock
    .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
    padding-left: 0;
    border: none;
    svg {
      display: none;
    }
  }
  .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
    height: auto;
  }
  .auth0-lock-form div div:nth-child(3) {
    display: flex;
    flex-direction: column;
  }
  .auth0-lock-social-button.auth0-lock-social-big-button {
    height: 48px;
    border: 1px solid #828282;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .auth0-lock-input-block.auth0-lock-input-email {
    order: 2;
  }
  .auth0-lock-input-block.auth0-lock-input-show-password {
    order: 3;
  }
  .auth0-lock-input-block.auth0-lock-input-full_name {
    order: 1;
  }
  .auth0-lock-social-button-icon {
    height: 48px !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-header-welcome {
    background: #2f2f60;
    color: #fff !important;
  }
  .auth0-lock-input {
    min-width: 100% !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-header-bg {
    background: #2f2f60 !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-input-wrap {
    background: transparent !important;
    border: none !important;
  }
  .auth0-lock-terms {
    display: none !important;
  }
  input {
    width: 100%;
    min-width: 100%;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    height: 48px;
    margin-bottom: 1.25rem;
  }
  .googlebtn {
    margin-top: 40px;
    .button-secondary-body {
      margin-bottom: 16px;
      border: 1px solid #343473;
    }
    .button-primary {
      background: #3377ea;
    }
  }
  .auth0-lock .auth0-lock-form div.auth0-lock-pane-separator {
    height: 1px;
    background: #bdbdbd;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0;
  }
  .auth0-lock-form p {
    display: none;
  }
  .loginfeild {
    .loginemail {
      margin-bottom: 25px;
    }
    .loginpass {
      margin-bottom: 32px;
    }
    label {
      font-weight: 600;
      font-size: 14pt;
      margin-bottom: 8px;
    }
  }
  .loginbottom {
    margin-top: 1rem;
    text-align: center;
    a {
      font-weight: 500;
      font-size: 14pt;
      margin-bottom: 20px;
      display: inline-block;
      color: #505de5;
    }
    p {
      font-weight: normal;
      font-size: 14pt;
    }
  }
  .guestcontainer {
    // margin-top: 1rem;
    .guestfield {
      margin-bottom: 1rem;
    }
    .guestemail {
      font-size: 13px;
      margin-left: 0;
      margin-bottom: 0;
    }
    .guestbtn {
      font-size: 13px;
      font-weight: 500;
    }
    .guest-email-invalid-hint {
      color: #f00;
      font-size: 12px;
      margin-top: 4px;
    }
  }
  .guest-separator {
    margin-top: 1rem;
  }
}
#signup {
  .auth0-lock.auth0-lock .auth0-lock-content-wrapper {
    flex-grow: 0;
  }
  a {
    color: #505de5;
  }
}
