@import '../../../assets/scss/index.scss';

#Terms_condition {
  background: url('../../../assets/images/abBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  @include padding(40px, 0, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(12px, 40px, 0, 0);
    background-attachment: fixed;
  }

  .admin-termspage {
    background: #ffffff;
    border: 1px solid #dbdbdb;
    border-top: 5px solid #e9eafa;
    border-radius: 6px;
    padding: 32px;
    max-width: auto;
    height: 100vh;
    margin-top: 1rem;
    @include media-breakpoint-down(lg) {
      padding: 32px 15px;
    }
    #toolbar {
      display: flex;
      background: #ffffff;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      margin-bottom: 15px;

      .ql-formats {
        display: flex;
        .ql-font {
          width: 90px;
        }
        .ql-size {
          width: 58px;
        }
        // width: 250px;
      }
      @include media-breakpoint-down(sm) {
        width: 350px;
      }
    }

    .text-editor {
      width: auto;
      margin: 0 auto;
      .editor-wrapper {
        height: 65vh;
      }
    }
    .ql-editor {
      min-height: 100px;
    }
    .title-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      h2 {
        font-size: 24px;
        @include media-breakpoint-down(sm) {
          font-size: 14pt;
        }
      }
    }

    .button-primary {
      height: 50px !important;
      width: 100px;
      background-color: rgba(194, 194, 255, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 21px;
      margin-left: auto;
      margin-right: 0px;
      color: #6b6bff;
      border-radius: 8px;
      cursor: pointer;

      .button-text {
        padding-left: 4px;
      }
    }
  }
  .termpera {
    margin-bottom: 25px;
  }
  h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .toast-wrapper {
    position: absolute;
    background-color: #ffffff;
    width: 300px;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.2);
    right: 60px;
    // left: 100vw;
    bottom: 20px;
    .toast-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value='sans-serif']::before {
  font-family: sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value='comic-sans']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New';
}
.ql-font-georgia,
.ql-font span[data-value='georgia']::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value='helvetica']::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value='lucida']::before {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value='extra-small']::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value='small']::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value='medium']::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value='large']::before {
  font-size: 20px !important;
}

.succesmsg.show {
  display: block;
}
.succesmsg {
  position: fixed;
  top: 50px;
  z-index: 99999999;
  background: #eefaef;
  border-radius: 8px;
  right: 0;
  left: 0;
  max-width: 360px;
  margin: 0 auto;
  padding: 16px 24px;
  height: 72px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  display: none;
  p {
    margin-bottom: 0;
    font-size: 14pt;
    line-height: 150%;
    img {
      margin-right: 15px;
    }
  }
}

.svg-icon {
  width: 1em;
  height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #4691f6;
}

.svg-icon circle {
  stroke: #4691f6;
  stroke-width: 1;
}
