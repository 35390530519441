@import '../../assets/scss/index.scss';

.heading {
  margin-bottom: 24px;
}

.search-add-table {
  border: 1px solid #cccccc;
  box-shadow: 1px 1px #d9d9d9;
  border-radius: 4px;
  tr {
    border-bottom: 1px solid #e6e6e6;
  }

  th,
  td {
    padding: 0.75rem 0.5rem;
    border: 1px solid #e6e6e6;
  }
}

.search-btn {
  margin-top: 1.5rem;
  height: 2.5rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 1.5rem;
}

.ir-form-container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.search-add-table {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
  thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    th {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14pt;
      line-height: 120%;
      color: #000000;
      width: 100%;
      text-align: left;
      // padding-bottom: 10px;
    }
  }
  tbody {
    background-color: rgb(250, 250, 250);
    tr {
      border-bottom: 1px solid #f2f2f2;
      td {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 120%;
        // width: 100%;
        color: #000000;
        padding: 12px 8px;
        margin: 10px 0;

        .generate-btn {
          margin-right: 1rem;
        }
      }
    }
    tr:nth-child(even) {
      background-color: rgb(242, 242, 242);
    }
  }
}

.ir-form-container {
  input.ir-form-input {
    min-width: 100% !important;
    height: 2.5rem;
    margin: 0;
    width: 100%;
  }

  .ir-form {
    width: 100%;
  }

  background-color: $water;
  width: auto;
  border: 1px solid #cbcbf3;
  box-sizing: border-box;
  border-radius: 4px;
  .form-wrapper {
    padding: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }
}
