@import '../../../assets/scss/index';
#unsure-points {
  background: $white;
  @include padding(40px, 40px, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(80px, 80px, 0, 0);
  }
  .row {
    @include flex-direction(column);
    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }
  width: 100%;
  p {
    font-size: 14pt;
    color: #4f4f4f;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  .four-tag {
    @include four-tag;
    padding-right: 0rem;
    margin-bottom: 25px;
    text-align: left;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      padding-bottom: 60px;
      padding-right: 6rem;
    }
    img {
      max-width: 51px;
      width: 100%;
      display: block;
      margin-bottom: 30px;
    }
  }
  .full-width {
    @include full-width;
    .search-home {
      @include flex-main;
      justify-content: center;
    }
  }
  .unsure-header {
    h2 {
      text-align: center;
      margin-bottom: 70px;
      font-size: 28px;
      font-weight: 700;
      color: #343473;
      @include media-breakpoint-down(md) {
        text-align: left;
        margin-bottom: 30px;
        margin-top: 20px;
      }
    }
  }
  .unsure-search {
    padding-top: 50px;
    p {
      color: #151147;
      font-size: 14pt;
      margin-bottom: 30px;
    }
  }
  .divder {
    height: 1px;
    background: #343473;
    opacity: 0.2;
  }
  .inpit-search.input {
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(34, 34, 34, 0.15);
    border-radius: 6px;
    outline: none;
  }
}

.homefootersearch {
  img {
    display: none;
  }
  button {
    height: 60px;
    margin: 4px;
  }
  p {
    margin-bottom: 0 !important;
    text-align: left !important;
  }
  h4 {
    text-align: left !important;
  }
  .ml-16 {
    margin-left: 16px;
  }
  input {
    box-shadow: 0px 2px 10px rgba(34, 34, 34, 0.15);
    border-radius: 6px;
    border: none;
    outline: none;
  }
}
