@import '../../assets/scss/index.scss';

.tab-container {
  .tab-list {
    display: flex;
    list-style: none;
    font-size: 14pt;
    padding: 0;
    margin: 8px;
    background: $navbar;

    .tabs {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      margin: 8px;
      color: #d3d3d3;
      padding: 8px;
      font-size: 14pt;
    }

    .active-tabs {
      background-color: $button;
      color: #fff;
    }

    .active-tabs::before {
      width: calc(100% + 2px);
      display: flex;
      align-items: center;
      color: $white;
      background-color: $button;
      padding: 2px 4px;
      text-decoration: none;
      margin: 4px;
      height: 45px;
    }
    .active-tabs::after {
      width: calc(100% + 2px);
      display: flex;
      align-items: center;

      background-color: $button;
      padding: 2px 4px;
      text-decoration: none;
      margin: 4px;
      height: 45px;
    }
  }
  .content {
    display: none;
    padding: 10px;
  }

  .active-content {
    display: flex;
  }
}

.risk-tab-list {
  display: flex;
  justify-content: space-between;
  width: 50%;
  background-color: #f7f7f7;
  padding: 20px;
  .risk-active-tabs {
    display: flex;
    color: #2f2f60;
    text-decoration: none;
    font-weight: 600;
    border-bottom: 5px solid #ffc47e;
  }
  .active-content {
    display: flex;
  }
}
