@import '../../assets/scss/index';

#header {
  height: 4.5rem;
  background: #fff;
  z-index: 99999999;
  position: fixed;
  width: 100%;
  padding: 0.5rem 0.25rem;
  box-shadow: 0px 1px 1px lightgray;

  &.transparent {
    background-color: transparent;
  }
  &.solid {
    background: $navbar;
  }

  .wrap {
    max-width: unset;
    margin: 0;
    padding: 0 10px;
  }

  @include media-breakpoint-up(xl) {
    display: block;
    .logo {
      width: 25%;
    }
    .nav {
      width: 75%;
      margin-left: auto;
    }
  }
  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include flex-main;
    @include flex-direction;
    @include media-breakpoint-down(xl) {
      justify-content: space-between;
      align-items: center;
    }
  }
  .logo {
    @include logo;
  }
  .nav {
    @include nav;

    @include media-breakpoint-up(xl) {
      display: flex !important;
      align-items: center;
    }
    @include media-breakpoint-down(xl) {
      ul {
        display: flex;
        flex-direction: column;
        padding: 1rem !important;
      }
    }
    a {
      color: #fff;
      line-height: 49px;
      font-size: 1.2rem;
      padding: 0 10px;
      display: block;
      text-decoration: none;
      padding-bottom: 7px;
    }
  }
  .nav {
    ul {
      display: flex;
      align-items: center;
      width: -webkit-fill-available;
      justify-content: space-evenly;
      li {
        padding: 0;
        margin: 0;
        display: flex;
        float: left;
        align-items: center;
        list-style: none;
        outline: 0 none;
        font-size: 12px;
        position: relative;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        @include media-breakpoint-down(xl) {
          width: 100%;
          text-align: left;
          border-bottom: 3px solid #2f2f60;
        }
        &.signup-list-item {
          padding: 0;
          border-left-width: 0px !important;
          border: 12px solid transparent;
          border-top-width: 12px !important;
          border-bottom-width: 12px !important;
        }
        .report-btn {
          -webkit-border-radius: 5px;
        }
        .landcheck-nav-btn {
          font-size: 14px;
          padding: 0.4rem;
          width: 5rem;
          &:hover {
            background-color: rgba(92, 92, 229, 0.7) !important;
          }
        }

        &.nav-search-li {
          &:not(.dropdown-nav-search-li) {
            margin-right: -1.5rem !important;
            margin-left: 40px !important;
          }
          .nav-search-btn {
            svg {
              width: 1.5em;
              height: auto;
              color: #151147;
            }
            color: #151147 !important;
            background: none;
            cursor: pointer;
            border-left-width: 1px !important;
            border-right-width: 1px !important;
            border-top-width: 12px !important;
            border-bottom-width: 12px !important;
            border: 12px solid transparent;
            font-size: 15px;
            font-family: Inter;
            line-height: 16px;
            font-weight: 400;
            padding: 0 10px 0 10px;
            display: block;
            outline: 0 none;
            position: relative;
            text-decoration: none;
            list-style: none outside none;
            margin: 0 !important;
            z-index: 10;
            vertical-align: baseline;
          }
        }

        a {
          color: #151147;
          font-weight: 400;
          border-bottom: 5px solid transparent;
          line-height: 1rem;
          margin-left: 13px !important;
          margin-right: 13px !important;
          font-size: 14px;
          padding: 0;

          &:not(.active) {
            text-decoration: none;
            background-image: linear-gradient(#ffb966, #ffb966);
            background-size: 0% 0.15em;
            background-position-y: 100%;
            background-position-x: 100%;
            background-repeat: no-repeat;
            transition: background-size 0.2s ease-in-out;

            &:hover,
            &:focus,
            &:active {
              border-bottom: 5px solid transparent;
              background-size: 100% 0.15em;
              background-position-x: 0%;
            }

            &#signup-link {
              border: 2px solid gray;
              color: #151147;
              font-weight: 400;
              -webkit-border-radius: 5px;
            }

            &.landcheck-nav-btn-link {
              border-bottom: none;
              transition: none;
              padding: 0.4rem;
              min-width: 4rem;
              text-align: center;
              &:hover,
              &:focus,
              &:active {
                border-bottom: none;
                background-image: none;
              }
            }
          }
          &.active {
            border-bottom: 1px solid #fff;
          }
        }
      }
    }
  }
  .menutoggle {
    display: none;
    @include media-breakpoint-down(xl) {
      display: block;
      cursor: pointer;
    }
  }
  .right-nav {
    margin-top: 2px;
  }
}

.nav.show {
  display: block !important;
  position: absolute;
  width: 100% !important;
  background: #2f2f60;
  right: 0;
  top: 70px;
  z-index: 99;
  padding-bottom: 15px;
}
.auth0-lock-header {
  display: none !important;
}
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  height: auto !important;
}
.auth0-lock.auth0-lock .auth0-lock-name {
  color: #fff;
  font-weight: 500;
}

.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit {
  height: 20px !important;
  line-height: 20px !important;
}
.auth0-lock.auth0-lock .auth0-lock-widget {
  width: 100% !important;
  max-width: 500px;
}

.table_center {
  display: table-cell;
  vertical-align: middle;
}
.drop-down {
  display: inline-block;
  position: relative;
}

.nav-vertical-list-menu ul {
  flex-direction: column;
}

.drop-down__name {
  color: #151147;
  font-size: 14px !important;
}

.drop-down__button {
  display: inline-block;
  line-height: 40px;
  padding: 0 18px;
  padding-bottom: 6px;
  text-align: left;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  &::before {
    content: '';
    background: url('../../assets/images/accicon.svg') no-repeat;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 20px;
    display: block;
    background-repeat: no-repeat;
  }
}

.drop-down__name {
  line-height: 49px;
  font-size: 1rem;
  padding: 0 30px !important;
  @include media-breakpoint-down(xl) {
    padding-left: 10px !important;
  }
}

.drop-down__icon {
  width: 18px;
  vertical-align: middle;
  margin-left: 14px;
  height: 18px;
  border-radius: 50%;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.drop-down__menu-box {
  position: absolute;
  width: max-content;
  left: 45px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  // margin-top: 5px;
}

.drop-down__menu {
  margin: 0;
  padding: 15px 10px !important;
  list-style: none;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
  .landcheck-nav-btn {
    margin-top: 1rem;
  }
  .nav-search-li {
    margin: 0 !important;
    .nav-search-btn {
      width: 100%;
      text-align: start;
      color: #151147 !important;
      svg {
        color: #151147;
      }
    }
  }
}
.drop-down__menu a {
  line-height: 28px !important;
  width: 100%;
}
.drop-down__menu li {
  padding-bottom: 5px !important;
  width: 100%;
}
.drop-down__menu-box:before {
  content: '';
  background-color: transparent;
  border-right: 8px solid transparent;
  position: absolute;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #fff;
  border-top: 8px solid transparent;
  top: -15px;
  right: 18px;
}

.drop-down__menu-box:after {
  content: '';
  background-color: transparent;
}

.drop-down__item {
  font-size: 18px !important;
  padding: 13px 0;
  text-align: left;
  font-weight: 500;
  color: #151147;
  cursor: pointer;
  position: relative;
  @include media-breakpoint-down(xl) {
    font-size: 16px !important;
    line-height: 40px !important;
  }
}

.drop-down__item-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0px;
  fill: #8995b6;
}

.drop-down__item:hover {
  color: #ba2636;
}

.drop-down__item:last-of-type {
  border-bottom: 0;
}

.drop-down--active .drop-down__menu-box {
  visibility: visible;
  opacity: 1;
  margin-top: 15px;
}

.drop-down__item:before {
  content: '';
  position: absolute;
  width: 3px;
  height: 28px;
  background-color: #ffb966;
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.drop-down__item:hover:before {
  display: block;
}

.drop-down__menu-box.showdrp {
  opacity: 1;
  visibility: visible;
}
.drop-down__menu {
  a {
    padding-left: 0 !important;
  }
}

.admin-dropdown-menu {
  background-color: #2f2f60;
  width: 220px;
  padding: 15px 0 !important;
  // height: 47px;
  a {
    padding: 0px 0px !important;
    line-height: 28px !important;
  }
  li {
    cursor: pointer;
    padding-top: 10px !important;
  }
  .dropdown-menu {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;

    // line-height: 100%;
    /* identical to box height, or 15px */

    // text-align: center;
  }
}

.nav.hide:not(.show) {
  @include media-breakpoint-down(xl) {
    display: none !important;
  }
}

@media only screen and (min-width: 1000px) {
  .wrap {
    max-width: 2000px !important;
    width: 100% !important;
    margin: 0 auto !important;
    padding: 0 70px !important;
  }
}

@media (max-width: 1640px) {
  .wrap {
    padding: 0 30px !important;
  }
}

@include media-breakpoint-up(xl) {
  .logo {
    width: 25% !important;
  }
  .nav {
    width: 75% !important;
    margin-left: auto !important;
  }
}
