@import '../../../assets/scss/index';
#trusted-home {
  background: $white;
  margin-top: 50px;
  @include padding(20px, 40px, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(60px, 80px, 0, 0);
    margin-top: -80px;
  }
  width: 100%;
  p {
    font-size: 14pt;
    color: #4f4f4f;
    font-weight: 400;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
      font-weight: 400;
    }
  }
  .sources {
    font-size: 13px;
  }
  .source-img {
    display: flex;
    img {
      width: auto !important;
    }
  }
  .search-home {
    @include flex-main;
  }
  .row {
    @include flex-direction(column);
    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }
  .half-width-img {
    @include half-width;
    text-align: left;
    padding-top: 50px;
    @include media-breakpoint-up(md) {
      text-align: left;
      padding-left: 6rem;
    }
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  .half-width {
    @include half-width;
    position: relative;
    text-align: left;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      text-align: left;
      margin-bottom: 0;
      padding-right: 6rem;
    }
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  h3 {
    margin-top: 25px;
  }
  h4 {
    font-size: 13px;
    color: #4f4f4f;
    margin-bottom: 0;
    margin-top: 15px;
  }
}
