@import '../../../assets/scss/index';

#banner {
  background-color: $banner-bg;

  @media (max-width: 500px) {
    &::before {
      content: '';
      height: 100px;
      width: 100px;
      display: block;
      position: absolute;
      right: 4%;
      background-size: 100%;
      top: 9%;
    }
  }

  @include padding(120px, 120px, 0, 0);

  @include media-breakpoint-down(md) {
    background: url('../../../assets/images/banner-home-mobile.png') no-repeat
      $banner-bg;
    background-position: bottom left;
    height: 600px;
    width: 100%;
    background-size: 100% 75%;
  }

  .batchBg {
    position: relative;

    &::before {
      content: '';
      height: 160px;
      width: 160px;
      display: block;
      position: absolute;
      right: -30px;
      background-size: 100%;
      top: 15%;

      @media (max-width: 1200px) {
        height: 110px;
        width: 110px;
        display: block;
        position: absolute;
        right: -100px;
        background-size: 100%;
        top: 23%;
      }

      @media (max-width: 767px) {
        right: 30px;
        top: 38%;
      }

      @media (max-width: 500px) {
        display: none;
      }
    }
  }

  .bannerContent {
    @include media-breakpoint-up(md) {
      max-width: 560px;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 0;
    background: url('../../../assets/images/banner-home.png') no-repeat
      $banner-bg;
    background-position: right top;
    background-size: 40% 100%;
    // min-height: 665px;
    // padding-top: 130px;

    min-height: 725px;
    padding-top: 190px;
  }

  width: 100%;

  p {
    color: $white;
    margin: 1em 0;
    font-size: 0.85rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
    }
  }

  .search-home {
    @include flex-main;
    width: fit-content;

    input {
      box-shadow: 0px 2px 10px rgba(34, 34, 34, 0.15);
      border-radius: 6px;
      border: none;
      outline: none;
    }

    button {
      height: 60px;
      width: 154px;
      margin-top: 4px;
      margin-left: 0.25rem;

      @include media-breakpoint-down(md) {
        margin-left: 15px;
        width: 98px;
      }
    }

    .rpsearch {
      img {
        display: none;
      }
    }
  }

  .report-disclaimer {
    margin-top: 60px;
    width: fit-content;

    p {
      font-size: 13px;
      color: lightgray;
      font-style: italic;
    }
  }

  .half-width-img {
    @include half-width;

    @include media-breakpoint-up(md) {
      width: 40%;
    }

    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    text-align: right;
    position: relative;

    img {
      margin: 0 auto;
      width: 100%;
      max-width: 435px;
    }
  }

  .half-width {
    @include half-width;

    @include media-breakpoint-up(md) {
      width: 60%;
    }

    position: relative;
  }

  .inpit-search.input {
    border: none;
    box-shadow: 0px 2px 10px rgba(34, 34, 34, 0.15);
  }

  .home-header {
    color: $orange;
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.4rem;
    }
  }
}

.pac-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  &::after {
    display: none !important;
  }

  .pac-item {
    border-top: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 16px;

    .pac-icon-marker {
      display: none !important;
    }

    .pac-item-query {
      display: block;
      margin-bottom: 0 !important;
      padding: 0 !important;
      color: #151147 !important;
      margin-top: 0;

      .pac-matched {
        color: #151147 !important;
        font-size: 14pt;
        font-weight: 500;
      }
    }

    span {
      font-size: 14pt;
      color: #828282;
      margin-top: -10px;
    }
  }
}

.addresh_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .button-primary {
    height: 60px !important;
  }
}

.serch-drp {
  min-width: 406px;

  @include media-breakpoint-down(md) {
    min-width: 100%;
  }

  .list-group {
    width: 100%;
    background: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 1px solid #e0e0e0;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    top: 46px;
  }

  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
  }

  .disabled-search-item {
    cursor: not-allowed;
    color: #a8a8a8 !important;
  }

  .enabled-search-item {
    cursor: pointer;
  }

  h4 {
    color: #151147 !important;
    font-size: 14pt;
    font-weight: 500;

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }

  p {
    font-size: 14pt;
    color: #828282;
    margin-top: -8px;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      font-size: 12px !important;
    }
  }
}

.unavailable-address {
  padding: 2px 6px;
  background: #f2f2f2;
  border-radius: 4px;
  color: #4f4f4f;
  font-size: 14pt;

  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
}

.fade.main-banner-search.wrap.serch-drp.show {
  max-width: 430px;
  width: 100%;

  @include media-breakpoint-down(md) {
    transform: translate(5px, 391px) !important;
  }
}

.search-field.form-control.active {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.search-field.form-control {
  width: 406px;
  margin-bottom: 0 !important;

  @include media-breakpoint-down(md) {
    width: 220px;
  }
}

.search-home {
  form.search {
    @include media-breakpoint-down(md) {
      margin-left: 15px;
    }

    .addresh_search {
      @include media-breakpoint-down(md) {
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
    }
  }

  .searchlist {
    .list-group {
      max-width: 406px !important;
      left: 12px !important;

      @include media-breakpoint-down(md) {
        max-width: 398px;
        left: 0 !important;
      }
    }
  }
}
