#rpbanner {
  background: #343473;
  padding-top: 5rem;
  padding-bottom: 50px;
  width: 100%;
  img {
    width: 100%;
  }
  .mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  h1 {
    font-size: 2rem;
    color: $white;
    margin-top: 24px;
  }
  input {
    width: 100%;
    background: #262660;
    border-radius: 6px;
    color: $white;
    border: none;
    height: 46px;
    margin-bottom: 15px;
    padding-left: 40px;
  }
  .rpsearch {
    position: relative;
    width: 100%;
    img {
      max-width: 20px;
      position: absolute;
      top: 16px;
      left: 10px;
    }
  }
  button {
    width: 100%;
    margin-top: 15px;
    @include media-breakpoint-up(lg) {
      width: auto;
      padding: 12px 24px;
    }
  }
  .button-secondary {
    margin-top: 15px;
    @include media-breakpoint-up(lg) {
      margin-left: 15px;
    }
  }
  p {
    color: $white;
    font-size: 1.2rem;
    margin-bottom: 5px;
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
  }
}

.search-field:focus-visible {
  outline: none;
}

#inlineFormInputName:focus-visible {
  outline: none;
}

.searchlist {
  p {
    font-size: 16px !important;
    color: #828282 !important;
    margin-top: -8px;
    margin-bottom: 0;
  }
  .wrap.serch-drp {
    .list-group {
      width: 406px;
      height: 240px;
      background: #fff;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top: 1px solid #e0e0e0;
      position: absolute;
      right: 0;
      z-index: 9999;
      top: 0;
      overflow: scroll;
      @include media-breakpoint-down(md) {
        width: 220px;
        height: 100%;
      }
    }
    .list-group-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      &:hover {
        background: #f2f2f2;
      }
    }
    .address-list {
      position: relative;
      left: -23px;
      font-size: 14pt;
      @include media-breakpoint-down(md) {
        height: 20vh;
        left: -11px;
      }
      .disabled-search-item {
        cursor: not-allowed;
        .search-city {
          color: #15114770;
        }
        .search-des {
          color: #82828270;
        }
      }
      .enabled-search-item {
        cursor: pointer;
        .search-city {
          color: #151147;
        }
        .search-des {
          color: #828282;
        }
      }
    }
  }
}
