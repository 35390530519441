@import '../../assets/scss/index.scss';

#contact {
  @include padding(40px, 80px, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(80px, 100px, 0, 0);
  }
  p {
    font-size: 14pt;
    color: #4f4f4f;
    font-weight: 400;
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
  .contactpage {
    background: #ffffff;
    border-radius: 6px;
    padding: 32px;
    max-width: 800px;
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
      padding: 32px 15px;
    }
  }
  .termpera {
    margin-bottom: 25px;
  }
  h2 {
    font-size: 28px;
    margin-bottom: 15px;
    @include media-breakpoint-down(lg) {
      font-size: 24px;
    }
  }
  #contactinput {
    .contactpage {
      @include media-breakpoint-down(lg) {
        padding: 20px 0px;
      }
    }
    p {
      a {
        color: #5c5ce5;
        font-size: 14pt;
        text-decoration: none;
      }
    }
  }
  .contactform {
    input {
      background: #ffffff;
      border: 1px solid #bdbdbd;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
    textarea {
      background: #ffffff;
      border: 1px solid #bdbdbd;
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      height: 160px;
      overflow: auto;
      padding: 10px 15px;
      outline: none;
    }
    label {
      font-weight: 600;
      font-size: 14pt;
      margin-top: 16px;
      margin-bottom: 8px;
    }
    button {
      margin-top: 25px;
    }
  }
  .accordion {
    margin-top: 40px;
    border: none;
  }
  .accordion__button {
    border-top: 1px solid #4f4f4f;
    position: relative;
    background: transparent;
    padding: 4px 2px;
    &::before {
      content: url(../../assets/images/chevron-up.svg);
      position: absolute;
      top: 20px;
      right: 20px;
      border: none;
      transform: rotate(0deg);
      @include media-breakpoint-down(lg) {
        right: 0px;
      }
    }
    p {
      font-weight: 600;
      font-size: 14pt;
      color: #151147;
      padding: 20px 0;
      @include media-breakpoint-down(lg) {
        width: 90%;
      }
    }
  }
  .accordion__item {
    position: relative;
  }
  .accordion__item:last-child {
    border-bottom: 1px solid #4f4f4f;
  }
  .accordion__panel {
    padding: 0;
    padding-bottom: 20px;

    p {
      font-size: 14pt;
      line-height: 150%;
      color: #4f4f4f;
    }
    ul {
      padding: 0 20px;
      margin-bottom: 0;
      li {
        font-size: 14pt;
        line-height: 150%;
        color: #4f4f4f;
      }
    }
    .secondul {
      margin-top: 20px;
      p {
        margin-bottom: 0;
        a {
          color: #5c5ce5;
          font-size: 14pt;
          text-decoration: none;
        }
      }
      ul {
        margin-top: 0;
      }
      ol {
        margin: 0;
      }
    }
  }
}

.second-question {
  p {
    margin-bottom: 0;
    a {
      color: #5c5ce5;
      font-size: 14pt;
      text-decoration: none;
    }
  }
  ul {
    margin-top: 0;
  }
  ol {
    margin: 0;
    margin-left: -24px;
  }
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(180deg) !important;
  position: absolute;
  top: 38px !important;
  right: 15px !important;
  border: none;
  @include media-breakpoint-down(lg) {
    right: -4px !important;
  }
}
.recptcha {
  margin-top: 16px;
}

.sub-list {
  padding-left: 0px;
}
