                    @mixin inline-flex {
                        display: -webkit-inline-box;
                        display: -webkit-inline-flex;
                        display: -moz-inline-flex;
                        display: -ms-inline-flexbox;
                        display: inline-flex;
                    }
                    
                    @mixin flex-direction($value: row) {
                        @if $value==row-reverse {
                            -webkit-box-direction: reverse;
                            -webkit-box-orient: horizontal;
                        }
                        @else if $value==column {
                            -webkit-box-direction: normal;
                            -webkit-box-orient: vertical;
                        }
                        @else if $value==column-reverse {
                            -webkit-box-direction: reverse;
                            -webkit-box-orient: vertical;
                        }
                        @else {
                            -webkit-box-direction: normal;
                            -webkit-box-orient: horizontal;
                        }
                        -webkit-flex-direction: $value;
                        -moz-flex-direction: $value;
                        -ms-flex-direction: $value;
                        flex-direction: $value;
                    }
                    
                    @mixin flex-wrap($value: nowrap) {
                        // No Webkit Box fallback.
                        -webkit-flex-wrap: $value;
                        -moz-flex-wrap: $value;
                        @if $value==nowrap {
                            -ms-flex-wrap: none;
                        }
                        @else {
                            -ms-flex-wrap: $value;
                        }
                        flex-wrap: $value;
                    }
                    
                    @mixin flex-flow( $values: ( row nowrap)) {
                        // No Webkit Box fallback.
                        -webkit-flex-flow: $values;
                        -moz-flex-flow: $values;
                        -ms-flex-flow: $values;
                        flex-flow: $values;
                    }
                    
                    @mixin order($int: 0) {
                        -webkit-box-ordinal-group: $int + 1;
                        -webkit-order: $int;
                        -moz-order: $int;
                        -ms-flex-order: $int;
                        order: $int;
                    }
                    
                    @mixin flex-grow($int: 0) {
                        -webkit-box-flex: $int;
                        -webkit-flex-grow: $int;
                        -moz-flex-grow: $int;
                        -ms-flex-positive: $int;
                        flex-grow: $int;
                    }
                    
                    @mixin flex-shrink($int: 1) {
                        -webkit-flex-shrink: $int;
                        -moz-flex-shrink: $int;
                        -ms-flex-negative: $int;
                        flex-shrink: $int;
                    }
                    
                    @mixin flex-basis($value: auto) {
                        -webkit-flex-basis: $value;
                        -moz-flex-basis: $value;
                        -ms-flex-preferred-size: $value;
                        flex-basis: $value;
                    }
                    
                    @mixin flex-main {
                        display: flex;
                    }
                    
                    @mixin flex($fg: 1, $fs: null, $fb: null) {
                        // Set a variable to be used by box-flex properties
                        $fg-boxflex: $fg;
                        // Box-Flex only supports a flex-grow value so let's grab the
                        // first item in the list and just return that.
                        @if type-of($fg)=="list" {
                            $fg-boxflex: nth($fg, 1);
                        }
                        -webkit-box-flex: $fg-boxflex;
                        -webkit-flex: $fg $fs $fb;
                        -moz-box-flex: $fg-boxflex;
                        -moz-flex: $fg $fs $fb;
                        -ms-flex: $fg $fs $fb;
                        flex: $fg $fs $fb;
                    }
                    
                    @mixin justify-content($value: flex-start) {
                        @if $value==flex-start {
                            -webkit-box-pack: start;
                            -ms-flex-pack: start;
                        }
                        @else if $value==flex-end {
                            -webkit-box-pack: end;
                            -ms-flex-pack: end;
                        }
                        @else if $value==space-between {
                            -webkit-box-pack: justify;
                            -ms-flex-pack: justify;
                        }
                        @else if $value==space-around {
                            -ms-flex-pack: distribute;
                        }
                        @else {
                            -webkit-box-pack: $value;
                            -ms-flex-pack: $value;
                        }
                        -webkit-justify-content: $value;
                        -moz-justify-content: $value;
                        justify-content: $value;
                    }
                    
                    @mixin align-items($value: stretch) {
                        @if $value==flex-start {
                            -webkit-box-align: start;
                            -ms-flex-align: start;
                        }
                        @else if $value==flex-end {
                            -webkit-box-align: end;
                            -ms-flex-align: end;
                        }
                        @else {
                            -webkit-box-align: $value;
                            -ms-flex-align: $value;
                        }
                        -webkit-align-items: $value;
                        -moz-align-items: $value;
                        align-items: $value;
                    }
                    
                    @mixin align-self($value: auto) {
                        // No Webkit Box Fallback.
                        -webkit-align-self: $value;
                        -moz-align-self: $value;
                        @if $value==flex-start {
                            -ms-flex-item-align: start;
                        }
                        @else if $value==flex-end {
                            -ms-flex-item-align: end;
                        }
                        @else {
                            -ms-flex-item-align: $value;
                        }
                        align-self: $value;
                    }
                    
                    @mixin align-content($value: stretch) {
                        // No Webkit Box Fallback.
                        -webkit-align-content: $value;
                        -moz-align-content: $value;
                        @if $value==flex-start {
                            -ms-flex-line-pack: start;
                        }
                        @else if $value==flex-end {
                            -ms-flex-line-pack: end;
                        }
                        @else {
                            -ms-flex-line-pack: $value;
                        }
                        align-content: $value;
                    }
                    
                    .row {
                        @include make-row;
                    }
                    
                    .wrap {
                        width: 100%;
                        @include media-breakpoint-up(md) {
                            max-width: 760px;
                        }
                        @include media-breakpoint-up(lg) {
                            max-width: 990px;
                        }
                        @include media-breakpoint-up(xl) {
                            max-width: 1200px;
                        }
                        margin: 0 auto;
                        padding: 0 15px;
                       
                    }
                    
                    .wrap-600 {
                        max-width:100%;
                        width: 100%;
                        text-align: center;
                        padding:0 15px ;
                        @include media-breakpoint-up(md) {
                            max-width: 600px;
                        }
                        @include media-breakpoint-up(lg) {
                            max-width: 700px;
                        }
                        margin: 0 auto;                       
                        overflow: hidden;
                         padding:0 15px;
                    }
                    
                    .wrap-1000 {
                        max-width: 100%;
                        width: 100%;
                        text-align: center;
                        @include media-breakpoint-up(md) {
                            max-width: 600px;
                        }
                        @include media-breakpoint-up(lg) {
                            max-width: 1100px;
                        }
                        margin: 0 auto;
                        padding: 0 15px;
                        overflow: hidden;
                    }
                    
                    // Logo
                    @mixin logo {
                        width: $width50percent;
                        line-height: 60px;
                        padding-left: 0;
                        @include media-breakpoint-up(md) {
                            width: auto;
                        }
                    }
                    
                    //navbar
                    @mixin nav {
                        color: $white;
                        text-align: right;
                        padding-right: 15px;
                        padding-top: 10px;
                        width: $width50percent;
                        @include media-breakpoint-up(md) {
                            width: auto;
                            padding-left: 20px;
                            text-align: left;
                        }
                        ul {
                            display: block;
                            padding: 0;
                            margin: 0;
                            li {
                                display: inline-block;
                                padding: 0 15px;
                                a {
                                    color: $white;
                                    line-height: 49px;
                                    padding: 0 10px;
                                    display: block;
                                    text-decoration: none;
                                    font-size: 1.2rem;
                                    position: relative;
                                    &:hover {
                                        border-bottom: 4px solid $orange;
                                        position: relative;
                                          @include media-breakpoint-down(md) {
                                                    border-bottom: none;
                                          }
                                    }
                                }
                            }
                            li.selected a {
                                border-bottom: 4px solid $orange !important;
                            }
                        }
                    }
                    
                    @mixin footer-logo {
                        line-height: 64px;
                        padding-left: 0;
                        @include make-col-ready();
                        @include make-col(12);
                        padding-bottom: 15px;
                        @include media-breakpoint-up(md) {
                            width: auto;
                            @include make-col(6);
                        }
                    }
                    
                    @mixin footer-nav {
                        color: $white;
                        text-align: left;
                        @include make-col-ready();
                        @include make-col(12);
                        @include media-breakpoint-up(md) {
                            text-align: right;
                            @include make-col(6);
                        }
                        ul {
                            display: block;
                            padding: 0;
                            margin: 0;
                            li {
                                display: inline-block;
                                padding: 0 15px;
                                a {
                                    color: $white;
                                    line-height: 49px;
                                    padding: 0 10px;
                                    display: block;
                                    text-decoration: none;
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                    
                    .hide {
                        display: none !important;
                        @include media-breakpoint-up(lg) {
                            display: block !important;
                        }
                    }
                    
                    .hide_n {
                        display: block;
                        @include media-breakpoint-up(lg) {
                            display: none;
                        }
                        img {
                            margin: 15px 0;
                            max-width: 60px;
                            width: 100%;
                            display: block;
                            border-radius: 50%;
                        }
                    }
                    
                    //padding
                    @mixin padding( $top, $bottom, $left, $right) {
                        padding-left: $left;
                        padding-right: $right;
                        padding-top: $top;
                        padding-bottom: $bottom;
                    }
                    
                    //border-radius
                    @mixin border-radius($value) {
                        border-radius: $value;
                    }
                    
                    @mixin full-width {
                        @include make-col-ready();
                        @include make-col(12);
                        //  @include media-breakpoint-up(sm) {
                        //     @include make-col(2);
                        //     padding-right: 20px;
                        //     text-align: left;
                        // }
                        @include media-breakpoint-up(md) {
                            @include make-col(12);
                        }
                        @include media-breakpoint-up(lg) {
                            @include make-col(12);
                        }
                        @include media-breakpoint-up(xl) {
                            @include make-col(12);
                        }
                    }
                    
                    @mixin half-width {
                        @include make-col-ready();
                        //  @include media-breakpoint-up(sm) {
                        //     @include make-col(2);
                        //     padding-right: 20px;
                        //     text-align: left;
                        // }
                        @include media-breakpoint-up(md) {
                            @include make-col(6);
                        }
                        @include media-breakpoint-up(lg) {
                            @include make-col(6);
                        }
                        @include media-breakpoint-up(xl) {
                            @include make-col(6);
                        }
                    }
                    
                    @mixin half-img {
                        @include make-col-ready();
                        @include media-breakpoint-up(md) {
                            @include make-col(6);
                        }
                        @include media-breakpoint-up(lg) {
                            @include make-col(6);
                        }
                        @include media-breakpoint-up(xl) {
                            @include make-col(6);
                        }
                    }
                    
                    @mixin four-tag {
                        @include make-col-ready();
                        @include media-breakpoint-up(md) {
                            @include make-col(4);
                        }
                        @include media-breakpoint-up(lg) {
                            @include make-col(4);
                        }
                        @include media-breakpoint-up(xl) {
                            @include make-col(4);
                        }
                    }
                    
                    .steps {
                        text-transform: uppercase;
                    }
                