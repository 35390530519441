@import '../../../assets/scss/index.scss';

.impactrating {
  text-align: center;

  h2 {
    font-weight: 700;
    font-size: 28px;
    color: #151147;
  }
  .rating-desc {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #4f4f4f;
    margin-bottom: 0;
  }

  button {
    background: #5c5ce5;
    border-radius: 6px;
    max-width: 375px;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 36px;
    font-weight: 600;
    font-size: 14pt;
  }

  .impactinnersection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 36px;

    .impactinnercircle {
      width: 156px;
      height: 156px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        margin-bottom: 0;
      }
    }

    .riskRatingLow {
      background: #a4dda9;
      color: #238258;
    }

    .riskRatingHigh {
      background: #e7a4ab;
      color: #ba2636;
    }

    .riskRatingLow-Med {
      background: #ffd479;
      color: #ab5e23;
    }

    .riskRatingMed-High {
      background: #fdc390;
      color: #b4523b;
    }
  }
}
