@import '../../../assets/scss/index';

#map-wrapper {
  margin-top: 16px;

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1000;
    width: 90%;
    height: 500px;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

#map-container {
  width: 100%;
  height: 500px;
  background-color: transparent;

  .map-dropdown {
    position: absolute;
    z-index: 100000;
    padding-right: 30px;
    padding-top: 30px;
    right: 0;

    #dropdown-basic {
      display: flex;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14pt;
      line-height: 150%;
      color: rgba(52, 52, 115, 1);
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: none;
      box-shadow: 3px 10px 47px -7px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 3px 10px 47px -7px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 3px 10px 47px -7px rgba(0, 0, 0, 0.75);
    }

    .dropdown-item {
      background-color: white;
      display: flex;
      flex-direction: column;
      width: 80%;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 4px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    a {
      text-decoration: none;
    }
  }
}

.button-reports {
  margin-top: 5px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.batch-icon {
  display: flex;
  justify-content: flex-end;
  margin-top: -175px;
  position: relative;
  z-index: 9999;
  margin-right: 20px;

  img {
    max-width: 160px;

    @media (max-width: 768px) {
      max-width: 105px;
    }
  }
}
