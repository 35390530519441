/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

h3 {
  font-size: 24px !important;
}

button.auth0-lock-submit {
  &:hover {
    background-color: rgba(92, 92, 229, 0.7) !important;
  }
}
