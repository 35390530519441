@import '../../../../../assets/scss/index.scss';

#menu-item-wrapper {
  .menu-item {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(lg) {
      margin-bottom: 8px;
    }

    #dropdown-basic {
      background: #ffffff;
      border: 1px solid #babaf5;
      box-sizing: border-box;
      border-radius: 4px;
      width: 220px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 45px;
    }
    .dropdown-item-wrapper {
      background-color: white;
      display: flex;
      flex-direction: column;
      width: 200px;
      justify-content: flex-start;
      z-index: 9999999;
      box-shadow: 10px 5px 10px lightgray;

      @include media-breakpoint-down(xl) {
        width: 74% !important;
      }

      .dropdown-item {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-decoration: none;
        padding: 4px;
      }
    }
  }
}
