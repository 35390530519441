@import '../../../assets/scss/index';
#riskp {
  @include padding(50px, 0, 0, 0);
  .report-priview.greenborder {
    border-top: 6px solid #d4e882;
    h3 {
      color: #4e7e37;
    }
  }
  .wrap-risk-level {
    .high {
      border-top: 6px solid #e7a4ab;
      h3 {
        color: #ba2636;
      }
    }
    .lowmed {
      border-top: 6px solid #d4e882;
      h3 {
        color: #4e7e37;
      }
    }
    .lowm {
      border-top: 6px solid #d4e882;
      h3 {
        color: #4e7e37;
      }
    }
    .med {
      border-top: 6px solid #ffd479 !important;
      h3 {
        color: #ab5e23;
      }
    }
    .medium {
      border-top: 6px solid #ffd479 !important;
      h3 {
        color: #ab5e23;
      }
    }
    .medhigh {
      border-top: 6px solid #fdc390;
      h3 {
        color: #b4523b;
      }
    }
    .low {
      border-top: 6px solid #a4dda9;
      h3 {
        color: #238258;
      }
    }
    .verylow {
      border-top: 6px solid #a4dda9;
      h3 {
        color: #238258;
      }
    }
    .notassessed {
      border-top: 6px solid #dbdbdb;
      h3 {
        color: #898989;
      }
    }
    .undetermined {
      border-top: 6px solid #dbdbdb;
      h3 {
        color: #898989;
      }
    }
    .possible {
      border-top: 6px solid #ffd479 !important;
      h3 {
        color: #ab5e23;
      }
    }
    .unlikely {
      border-top: 6px solid #a4dda9;
      h3 {
        color: #238258;
      }
    }
  }
  .report-priview {
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    h3 {
      color: #b4523b;
      font-weight: 600;
      font-size: 14pt;
      margin-top: 8px;
    }
    h2 {
      font-weight: 600;
      font-size: 48px;
      color: #151147;
      margin-top: 10px;
      @include media-breakpoint-down(md) {
        font-size: 32px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
  }
  .actiond {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .actionm {
    display: none;
    max-width: 250px !important;
    @include media-breakpoint-down(md) {
      display: block;
      max-width: 250px !important;
      margin-top: -50px !important;
    }
  }
  .flooding {
    .flodingMain {
      display: flex;
      justify-content: space-between;
      text-align: center;
      align-items: stretch;
      position: relative;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        width: 100%;
        text-align: center;
        &:first-of-type {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
        &:last-of-type {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
        p {
          margin-bottom: 0;
          padding: 6px;
          font-weight: 500;
          font-size: 14px;
          @include media-breakpoint-down(md) {
            padding: 0px;
          }
        }
      }
      .lowf {
        background: #a4dda9;
        opacity: 0.8;
        color: #238258;
      }
      .verylow {
        background: #a4dda9;
        opacity: 0.8;
        color: #238258;
      }
      .lowMf {
        background: #d4e882;
        opacity: 0.8;
        color: #4e7e37;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      .MedHf {
        background: #fdc390;
        color: #b4523b;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      img {
        position: absolute;
        height: 24px;
        right: 10%;
        z-index: 2;
        top: -15px;
        @include media-breakpoint-down(md) {
          height: 20px;
          top: -15px;
        }
      }
      .suborg {
        bottom: -15px;
        top: auto;
      }
      .medlowf {
        background: #e7a4ab;
        opacity: 0.8;
        color: #ba2636;
      }
      .MediHf {
        color: #ab5e23;
        background: #ffd479;
      }
      .notassessed {
        color: #898989;
        background: #dbdbdb;
        border-top: 0 !important;
      }
      .possible {
        color: #898989;
        background: #dbdbdb;
      }
    }
  }
  .acmobile {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .f-tooltip {
    background: #ffffff;
    border: 1px solid #5c5ce5;
    box-sizing: border-box;
    box-shadow: 0px 4px 16px rgba(34, 34, 34, 0.15);
    border-radius: 6px;
    max-width: 520px;
    margin-left: 35%;
    position: relative;
    margin-bottom: 18px;
    height: 58px;
    @include media-breakpoint-down(md) {
      margin-left: 1%;
      height: auto;
      padding: 5px 0;
    }
  }
  .rp-disclaimer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
    flex-wrap: wrap;
    p {
      font-size: 14pt;
      font-style: italic;
      color: #999999 !important;
    }
  }
  .rp-contant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    p {
      max-width: 700px;
      font-size: 14pt;
      color: #4f4f4f;
    }
    .rp-lbtn {
      display: flex;
      justify-content: flex-end !important;
      align-self: end;
      flex-wrap: wrap;
      max-width: 70%;
      margin-bottom: 6px;
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
        max-width: 100%;
        justify-content: flex-start !important;
      }
      .property {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px !important;
        font-size: 13px !important;
        color: #4f4f4f;
        img {
          margin-right: 5px;
          width: 15px !important;
          height: 15px !important;
        }
      }
      .pr-l {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px !important;
        font-size: 13px !important;
        color: #4f4f4f;
        img {
          margin-right: 5px;
          width: 15px !important;
          height: 15px !important;
        }
      }
    }
  }
  .pr-rating {
    margin-top: 40px;
    .pr-rating-heading {
      img {
        width: 100%;
        @include media-breakpoint-down(md) {
          height: 360px;
        }
      }
    }
    h2 {
      font-weight: bold;
      font-size: 24px;
      color: #151147;
      margin-bottom: 0;
    }
    p {
      font-size: 14pt;
      color: #4f4f4f;
      margin-bottom: 5px;
    }
    ul {
      padding: 0 15px;
      margin-top: 0;
      li {
        font-size: 14pt;
        color: #4f4f4f;
      }
    }
    h3 {
      color: #151147;
      font-weight: 600;
      font-size: 14pt;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
  .maparea {
    h3 {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 20px;
    }
    .mainmap {
      img {
        width: 100%;
        @include media-breakpoint-down(md) {
          object-fit: cover;
        }
      }
    }
    .mainmap-caption {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: end;
      line-height: 0.5;
    }
  }
  .mapcontant {
    margin-top: 50px;
    h3 {
      font-size: 14pt;
    }
    a {
      font-weight: 500;
      font-size: 14pt;
      color: #151147;
      text-decoration: none;
      img {
        margin-left: 10px;
      }
    }
    .map1 {
      @include make-col-ready();
      width: 100%;
      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          margin-left: 20px;
        }
        img {
          height: 24px;
        }
        a {
          font-size: 0;
          img {
            height: 14px;
          }
        }
      }
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(4);
      }
    }
  }
  .mapcontantDetails {
    h2 {
      margin: 20px 0;
    }
    h4 {
      font-size: 14pt;
    }
    p {
      margin-bottom: 16px;
    }
    .mapcontantModal {
      margin-top: 2.5rem;
    }
  }
}
.mapdetails {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  p {
    font-size: 13px;
    line-height: 150%;
    color: #4f4f4f;
  }
  .maptime {
    margin-left: 20px;
    @include media-breakpoint-down(md) {
      margin-left: 0px;
    }
  }
}

.lowrisk {
  @include media-breakpoint-down(md) {
    .row {
      flex-wrap: nowrap;
      overflow: scroll;
    }
  }
}

.actionsModal {
  h2 {
    font-weight: 600;
    font-size: 14pt;
    line-height: 150%;
    color: #343473;
  }
  .modal-body {
    padding: 0 20px;
    ul {
      padding: 0 20px;
      li {
        font-size: 14pt;
        line-height: 150%;
        a {
          color: #5c5ce5;
          font-weight: 600;
        }
      }
    }
    .modalimg {
      display: flex;
      margin-top: 10px;
      img {
        width: 100%;
        max-width: 220px;
        &:nth-child(2) {
          margin-left: 10px;
        }
      }
    }
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.fade {
  transition: opacity 0.15s linear;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translateY(-50px);
}
.modal-dialog {
  max-width: 680px;
  margin: 5.75rem auto !important;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 16px;
  outline: 0;
  padding: 10px;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  min-width: 40px;
  span {
    display: none;
  }
}
.modal-header .close {
  padding: 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title.h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: #151147;
}

.financialModal {
  h2 {
    font-size: 14pt;
    margin-bottom: 0;
  }
  p {
    font-size: 14pt;
    line-height: 150%;
    color: #000000;
    margin-bottom: 30px;
  }
  a {
    color: #5c5ce5;
    font-weight: 600;
  }
  .modal-body {
    padding: 10px 20px;
  }
  .linkmodal {
    margin-top: 20px;
    p {
      margin-bottom: 0;
    }
  }
}
.permantfixesModal {
  h2 {
    font-size: 14pt;
    margin-bottom: 0;
  }
  p {
    font-size: 14pt;
    line-height: 150%;
    color: #000000;
    margin-bottom: 30px;
  }
  a {
    color: #5c5ce5;
    font-weight: 600;
  }
  .modal-body {
    padding: 10px 20px;
    ul {
      padding: 0 20px;
      li {
        font-size: 14pt;
        line-height: 150%;
      }
    }
  }
  .linkmodal {
    margin-top: 20px;
    p {
      margin-bottom: 0;
    }
  }
}

.mapModal {
  .modal-body {
    padding: 0 10px 10px 10px;
  }
  img {
    max-width: 100%;
    height: 600px;
  }
}

.risk-header {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  img {
    margin-right: 1rem;
  }
  h2 {
    margin-top: 0 !important;
  }
}
