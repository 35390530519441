@import '../../../assets/scss/index';
#preview-property {
  background: $banner-text;
  @include padding(10px, 40px, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(40px, 40px, 0, 0);
  }
  width: 100%;
  p {
    font-size: 14pt;
    color: #4f4f4f;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
    }
  }
  .row {
    @include flex-direction(column-reverse);
    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }
  .half-width-img {
    @include half-width;
    text-align: center;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      text-align: right;
      margin-bottom: 0;
    }
    img {
      margin: 0 auto;
      width: 100%;
    }
  }
  .half-width {
    @include half-width;
    position: relative;
    text-align: left;
    @include media-breakpoint-up(md) {
      text-align: left;
      padding-top: 20px;
      padding-right: 4rem;
    }
  }
  .steps {
    font-weight: 700;
    color: #5c5ce5;
    font-size: 14px;
  }
  .stepcontant {
    font-size: 14pt;
    margin-bottom: 30px;
  }
  .question {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}
