.paymentinput {
  width: 100%;
  min-width: 100%;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: 10px 10px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.87);
}
.paymentsection {
  display: flex;
  width: 100%;
  .paymentsectionname {
    width: 100%;
    &:nth-child(2) {
      margin-left: 20px;
      width: 50%;
    }
  }
}
.canbtn {
  border: 1px solid #151147;
  box-sizing: border-box;
  border-radius: 6px;
  color: #151147;
  font-weight: 600;
  font-size: 14pt;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: transparent !important;
  border-radius: 6px;
  width: 100%;
  margin-top: 15px;
}
#tccontainer {
  display: flex;
  align-items: center;
  #tandc {
    width: 22px !important;
    min-width: auto !important;
    margin-bottom: 12px;
    margin-right: 1rem;
    accent-color: #5c5ce5;
  }
  a {
    color: #5c5ce5;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translateY(-50px);
}
.modal-dialog {
  max-width: 680px;
  margin: 5.75rem auto !important;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 16px;
  outline: 0;
  padding: 10px;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-body {
  padding: 10px 20px;
}
