@import '../../assets/scss/index';
.con{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 258px) ;
}

html,
body{
    -webkit-print-color-adjust: exact;
    size: 210mm 297mm ;
    font-size: 14pt !important;
}
div#root {
  margin: 0;
  padding: 0;
}
.downloadpdfmain{
    width: 100%; 
    margin: 0 auto; 

    .impactrating .impactinnersection .impactinnercircle {
      width: 240px;
      height: 240px; 

      p {
        font-size: 96px;
      }
    }
}
.react-pdf__Page__canvas { 
    transform: scale(0.7) !important;
    font-size: 14pt !important;
}
.pagehight{
    height: "297mm"
}
div#root {
  font-family: Inter, Arial, Helvetica, sans-serif;
}

#riskp {
  .wrap {
    max-width: 1600px !important;
  }
}
section.pdf-body {
  height: 100%;
  margin: 8px;
  font-size: 14pt !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex {
  flex: 1 1 auto;
}

input,
textarea {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 8px;
  margin: 4px;
}

section.header-bar {
  padding: 16px;
  background-color: #eee;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

section.header-bar .header {
  font-size: 1.2em;
}

section.pdf-toolbar {
  padding: 16px;
}

section.pdf-toolbar button {
  background-color: #fff;
  border: 1px solid #555;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}

section.pdf-toolbar button:hover,
section.pdf-toolbar button:focus {
  border: 1px solid #333;
  background-color: #eee;
}
 
.riskRate .half-width {
    margin: 20px 0px;
}

.aboutReport {
  margin-top: 1.5rem;
  padding: 0 15px;
}

.risk-info {
  padding: 15px;
}