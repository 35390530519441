@import '../../../assets/scss/index';
#hazard-report {
  background: url('../../../assets/images/wave.png') right top no-repeat
    $banner-text;
  @include padding(80px, 40px, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(40px, 50px, 0, 0);
  }
  width: 100%;
  p {
    font-size: 14pt;
    color: #4f4f4f;
    font-weight: 400;
    @include media-breakpoint-up(lg) {
      font-size: 20px;
      color: #4f4f4f;
      font-weight: 400;
    }
  }
  .full-width {
    @include full-width;
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: center;
    }
    img {
      max-width: 481px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 25px;
      display: block;
    }
  }
  h2 {
    font-size: 28px;
  }
}
