@import '../../../assets/scss/index';
#search-property {
  background: url('../../../assets/images/wave2.png') left top no-repeat
    $banner-text;
  @include padding(40px, 40px, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(40px, 40px, 0, 0);
  }
  width: 100%;
  p {
    font-size: 14pt;
    color: #4f4f4f;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
    }
  }
  .search-home {
    @include flex-main;
  }
  .row {
    @include flex-direction(column);
    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }
  .half-width-img {
    @include half-width;
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
      padding-right: 4rem;
    }
    img {
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .half-width {
    @include half-width;
    position: relative;
    text-align: left;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      text-align: left;
      margin-bottom: 0;
      padding-left: 4rem;
      padding-top: 30px;
    }
  }
  .question {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .steps {
    font-weight: 700;
    color: #5c5ce5;
    font-size: 14px;
    margin-top: 40px;
    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }
  }
  .stepcontant {
    font-size: 14pt;
  }
}
