@import '../../assets/scss/index.scss';

#About {
  background: url('../../assets/images/abBg.png') no-repeat;
  background-size: cover;
  background-position: center;

  @include padding(40px, 80px, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(80px, 80px, 0, 0);
    min-height: 74vh;
    background-attachment: fixed;
  }
  p {
    font-size: 14pt;
    color: #4f4f4f;
    font-weight: 400;
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
  .termspage {
    background: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    padding: 32px;
    max-width: 800px;
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
      padding: 32px 15px;
    }
  }
  .termpera {
    margin-bottom: 25px;

    .about-para {
      font-size: 1.5rem;
      line-height: 150%;
      color: #151147;
    }
  }
  h1 {
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
  }
  .search-field.form-control {
    width: 406px;
    margin-bottom: 0 !important;
    @include media-breakpoint-down(md) {
      width: 220px;
      margin-right: 20px;
    }
  }

  .full-width {
    @include full-width;
    .search-home {
      @include flex-main;
      justify-content: center;
    }
  }

  .unsure-search {
    padding-top: 50px;
    p {
      color: #151147;
      font-size: 14pt;
      margin-bottom: 30px;
    }
  }
  .inpit-search.input {
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(34, 34, 34, 0.15);
    border-radius: 6px;
    outline: none;
  }
}

.homefootersearch {
  img {
    display: none;
  }
  button {
    height: 60px;
    margin: 4px;
  }
  p {
    margin-bottom: 0 !important;
    text-align: left !important;
  }
  h4 {
    text-align: left !important;
  }
  .ml-16 {
    margin-left: 16px;
  }
  input {
    box-shadow: 0px 2px 10px rgba(34, 34, 34, 0.15);
    border-radius: 6px;
    border: none;
    outline: none;
  }
}

.aboutHeader {
  display: flex;
  justify-content: space-between;

  img {
    width: 130px;
  }
}
