@import '../../assets/scss/index.scss';

#riskdefinition {
    background: url('../../assets/images/abBg.png') no-repeat;
    background-size: cover;
    background-position: center;
    @include padding(40px, 80px, 0, 0);

    @include media-breakpoint-up(md) {
        min-height: 74vh;
        background-attachment: fixed;
    }

    .innerwrap {
        max-width: 900px;
    }
}