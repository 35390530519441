@import '../../../assets/scss/index';
#map-area {
  background: $banner-bg;
  color: $white;
  @include padding(20px, 0, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(40px, 70px, 0, 0);
  }
  @include media-breakpoint-down(md) {
    @include padding(40px, 70px, 0, 0);
  }
  width: 100%;
  h2 {
    color: $white;
    font-size: 28px;
  }
  p {
    font-size: 0.85rem;
    color: $white;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      margin-bottom: 35px;
    }
  }
  .full-width {
    position: relative;
    @include full-width;
    text-align: left;
    @include media-breakpoint-up(md) {
      text-align: center;
    }
    .map-loader {
      width: 100%;
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .map-label {
      position: absolute;
      z-index: 100000;
      padding-right: 30px;
      padding-top: 30px;
      margin-top: 30px;
      margin-right: 30px;
      right: 0;

      p {
        text-align: center;
        color: rgba(52, 52, 115, 1);

        span {
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 3px 10px 47px -7px rgba(0, 0, 0, 0.75);
          -webkit-box-shadow: 3px 10px 47px -7px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 3px 10px 47px -7px rgba(0, 0, 0, 0.75);
        }
      }
    }

    .map-container {
      width: 100%;
      height: 500px;
      .map-dropdown {
        position: absolute;
        z-index: 100000;
        padding-right: 30px;
        padding-top: 30px;
        right: 0;

        #dropdown-basic {
          display: flex;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14pt;
          line-height: 150%;
          color: rgba(52, 52, 115, 1);
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border: none;
          box-shadow: 3px 10px 47px -7px rgba(0, 0, 0, 0.75);
          -webkit-box-shadow: 3px 10px 47px -7px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 3px 10px 47px -7px rgba(0, 0, 0, 0.75);
          svg {
            margin-left: 8px;
            margin-top: 2px;
          }
        }
        .dropdown-item {
          background-color: white;
          display: flex;
          flex-direction: column;
          width: 80%;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 4px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
        a {
          text-decoration: none;
        }
      }
    }
    img {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  .tyu {
    text-transform: uppercase;
    color: $orange;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .dmap {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }
  .mobilemap {
    display: none !important;
    @include media-breakpoint-down(md) {
      display: block !important;
    }
  }
}
.sr-only {
  display: none;
}

.tooltip {
  padding: 24px;
  .tooltip-title {
    color: $blue-dark;
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px !important;
    @media (max-width: 991px) {
      margin-bottom: 10x !important;
    }
  }
  .tooltip-subtitle {
    //styleName: Desktop / Label;
    font-family: Inter;
    font-size: 14pt;
    font-style: normal;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: left;
    color: $blue-dark;
  }
  .tooltip-subtitle1 {
    font-family: Inter;
    font-size: 14pt;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: $blue-dark;
  }
  .tooltip-risk-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 6px !important;
    margin-bottom: 24px !important;

    @media (max-width: 991px) {
      margin-bottom: 12x !important;
    }
    .tooltip-risk {
      display: flex;
      width: auto;
      padding: 0px 5px;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
      padding: 6px 12px;
      /* Primary */

      background: #d6d6f8;
      border-radius: 6px;
      border: 1px solid #d6d6f8;
      img {
        margin-right: 8px !important;
        height: 16px;
        width: 16px !important;
      }

      .tooltip-risk-text {
        color: $blue-dark;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14pt;
        line-height: 150%;
        /* identical to box height, or 27px */

        display: flex;
        padding-left: 12;
        align-items: center;
        text-align: center;
      }
    }
  }
  .tooltip-otherrisk-container {
    columns: 2 auto;
    margin: 8px;
    .tooltip-risk {
      display: flex;
      align-items: center;
      margin: 4px;
      background: #d3d3d3;
      padding: 8px;
      border-radius: 6px;
      border-width: 1;
      border-color: $blue-dark;
      margin-left: 0;
      img {
        margin-right: 8px !important;
        height: 16px;
        width: 16px !important;
      }

      .tooltip-risk-text {
        color: $blue-dark;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14pt;
        /* identical to box height, or 27px */

        display: flex;
        padding-left: 12;
        align-items: center;
        text-align: center;
      }
    }
  }
}

.leaflet-popup .leaflet-popup-content {
  @media (min-width: 1200px) {
    width: 410px !important;
  }
  @media (min-width: 768px) {
    width: 340px !important;
  }
}

.tooltip-risk-container .tooltip-risk {
  margin-left: 0px !important;
}

.tooltip-otherrisk-container {
  margin: 12px 0px !important;
}
.tooltip {
  .tooltip-otherrisk-container .tooltip-risk {
    background: #fff !important;
    border: 1px solid grey !important;
    border-bottom: 1px solid grey !important;
    width: auto !important;
  }
  .tooltip-subtitle1 {
    font-weight: normal !important;
    font-family: inherit;
  }
  .tooltip-subtitle {
    font-weight: bold !important;
  }

  .tooltip-otherrisk-container {
    columns: auto !important;
    display: flex;
    flex-wrap: wrap;
  }
}
.tooltip-otherrisk-footer-detail {
  border-top: 1px solid #000;
  margin-top: 25px;
  padding-top: 15px;
  @media (max-width: 991px) {
    margin-top: 10px;
    padding-top: 8px;
  }
}
.tooltip-otherrisk-footer-detail p {
  font-size: 16px !important;
  color: #4f4f4f !important;
  margin: 0 !important;
}

@media (max-width: 991px) {
  .leaflet-pane.leaflet-popup-pane {
    z-index: 99999 !important;
  }
}

.MapRiskComponentMaxWidth {
  .modal-content {
    max-width: 410px;
    margin: 0 auto;
  }
}

.riskmappopover {
  max-width: 450px;
  width: 100%;
  background-color: #ffff;
  position: absolute;
  z-index: 999999;
  border-radius: 6px;
  box-shadow: 0px 4px 16px rgba(34, 34, 34, 0.15);
  &::before {
    content: '';
    border-bottom: solid 20px #fff;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
    border-radius: 2px;
    position: absolute;
    top: -20px;
    left: calc(50% - 20px);
  }
  .tooltip-risk-container {
    p {
      margin-bottom: 0 !important;
    }
  }
  p {
    color: #151147 !important;
    margin-bottom: 8px !important;
  }
}
