@import '../../../../assets/scss/index.scss';

#dropdown-wrapper {
  background-color: $water;
  width: auto;
  border: 1px solid #cbcbf3;
  box-sizing: border-box;
  border-radius: 4px;
  .menu-wrapper {
    padding: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: flex-end;
    @include media-breakpoint-down(xl) {
      flex-direction: column;
    }
    .menu-go {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      background-color: #6b6bff;
      border-radius: 45px;
      align-self: flex-end;
      cursor: pointer;
      span {
        font-weight: 900;
        color: $white;
      }
    }
  }
  .menu-item {
    display: flex;
    flex-direction: column;
  }
}
