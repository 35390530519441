@import '../../assets/scss/index.scss';

#toolbar {
  display: flex;
//   width: 350px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 0px;
  align-items: center;
  border: none;
//   button {
//     width: 20px;
//   }

  .ql-formats {
    display: flex;
    align-items: center;
    .ql-font {
      width: 90px;
    }
    .ql-size {
      width: 58px;
    }
  }
  @include media-breakpoint-down(sm) {
    width: 260px;
  }
}

#toolbar1,
#toolbar2,
#toolbar3,
#toolbar4,
#toolbar5,
#toolbar7 {
  display: flex; 
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom:0px;
  border: none;
  align-items: center;
 
//   button {
//     width: 20px;
//   }

  .ql-formats {
    display: flex;
    align-items: center;
    .ql-font {
      width: 90px;
    }
    .ql-size {
      width: 58px;
    }
  }
  @include media-breakpoint-down(sm) {
    width: 260px;
  }
}

/* Set content for font-families */

.ql-font-arial,
.ql-font span[data-value='arial']::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value='comic-sans']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New';
}
.ql-font-georgia,
.ql-font span[data-value='georgia']::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value='helvetica']::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value='lucida']::before {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value='extra-small']::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value='small']::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value='medium']::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value='large']::before {
  font-size: 20px !important;
}
