@import '../../assets/scss/index';

#emailverified {
  padding-top: 130px;
  padding-bottom: 40vh;
  background: url('../../assets/images/abBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    padding-bottom: 10vh;
  }
  .emailverified-inner {
    max-width: 560px;
    margin: 0 auto;
    text-align: center;
    h2 {
      font-weight: bold;
      font-size: 28px;
      color: #151147;
    }
    p {
      font-size: 14pt;
      line-height: 150%;
      color: #4f4f4f;
      margin: 32px 0;
    }
    button {
      background: #5c5ce5;
      border-radius: 6px;
      font-weight: 600;
      font-size: 14pt;
      line-height: 150%;
      color: #ffffff;
      max-width: 400px;
      border: none;
      width: 100%;
    }
  }
}
