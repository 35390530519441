@import '../../assets/scss/index';

#payment {
  .row {
    @include flex-direction(column);

    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }

  padding: 40px 0;

  @media (max-width: 768px) {
    padding: 40px 0 0;
  }

  label {
    font-weight: 600;
    font-size: 14pt;
    margin-bottom: 8px;
    color: var(--bs-body-color);
  }

  .login-inner {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
  }

  .paymentmain {
    background: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    padding: 30px 40px;

    @media (max-width: 768px) {
      padding: 20px;
    }
  }

  h1 {
    font-size: 28px;
    margin-bottom: 30px;
    padding-left: 5px;
  }

  h2 {
    font-weight: 600;
    font-size: 14pt;
    line-height: 150%;
    margin-bottom: 25px;
  }

  h3 {
    font-weight: 600;
    font-size: 14pt;
    line-height: 150%;
    margin-bottom: 25px;
  }

  .sign-frm {
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 32px 40px;

    @media (max-width: 768px) {
      padding: 16px;
    }

    h3 {
      font-weight: 600;
      font-size: 14pt;
      color: #151147;
    }

    p {
      font-size: 14pt;
      color: #4f4f4f;
    }

    span {
      font-size: 13px;
      color: #4f4f4f;
      margin-top: 8px;
      display: inline-block;
      width: 100%;
    }
  }

  .sign-img {
    position: relative;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 0px;
      border: 1px solid #dbdbdb;
      border-radius: 6px;
      margin-bottom: 10px;
    }

    img {
      max-width: 100%;
      width: 100%;
      height: 290px;
      object-fit: cover;

      @media (max-width: 768px) {
        width: 50%;
        border-radius: 6px;
        height: 220px;
      }
    }

    .sign-img-contant {
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 55px 40px;
      margin-top: -20px;
      padding-bottom: 30px;

      @media (max-width: 768px) {
        width: 50%;
        margin-top: 0;
        border: none;
        padding: 16px;
        text-align: left;
      }
    }

    p {
      font-size: 14pt;
      color: #000000;
      margin-bottom: 5px;

      @media (max-width: 768px) {
        font-size: 14pt;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 14pt;
      color: #151147;

      @media (max-width: 768px) {
        font-size: 14pt;
      }
    }

    .b-img-contant {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        height: 80%;
      }

      p {
        color: #151147;
      }
    }
  }

  .sbmbtn {
    width: 100%;
    font-weight: 600;
    font-size: 14pt;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: #5c5ce5 !important;
    border-radius: 6px;
    color: #fff !important;
    border: none;
    margin-top: 20px;
  }

  .half-width-img {
    @include half-width;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
      padding-right: 4rem;
    }

    @media (max-width: 768px) {
      order: 1;
    }
  }

  .half-width {
    @include half-width;
    position: relative;
    text-align: left;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      text-align: left;
      margin-bottom: 0;
      padding-left: 1rem;
    }

    @media (max-width: 768px) {
      order: 2;
    }
  }

  input {
    width: 100%;
    min-width: 100%;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    height: 48px;
    margin-bottom: 25px;
    color: #151147;

    &::placeholder {
      color: #151147;
      opacity: 1;
    }
  }
}

.loaderCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 258px);
}

.payment-secure {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  img {
    height: 65px;

    @include media-breakpoint-up(md) {
      height: 84px;
    }
  }
}

.payment-batch-icon {
  position: absolute;
  right: 30px;
  top: 42%;
  @media (max-width: 768px) {
    left: 40%;
    top: 40%;
    right: auto;
  }

  img {
    max-width: 160px !important;
    height: 160px !important;

    @media (max-width: 991px) {
      max-width: 120px !important;
      height: 120px !important;
    }

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
}
