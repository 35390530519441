@import '../../../assets/scss/index';

#stress-property {
  background: $white;
  @include padding(40px, 40px, 0, 0);

  @include media-breakpoint-up(md) {
    @include padding(35px, 0px, 0, 0);
  }

  width: 100%;

  p {
    font-size: 14pt;
    color: #4f4f4f;

    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
    }
  }

  .search-home {
    @include flex-main;
    margin-bottom: 70px;

    @media (max-width: 767px) {
      margin-bottom: 0px;
    }
  }

  .wrap-full {
    padding: 0 15px;
  }

  .row {
    @include flex-direction(column-reverse);

    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }

  .half-width-img {
    display: none;
    @include half-width;
    text-align: left;

    @include media-breakpoint-up(md) {
      display: block;
      text-align: left;
    }

    img {
      width: 100%;

      @media (max-width: 1420px) {
        min-height: 740px !important;
        object-fit: cover;
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        margin-top: -30px;
        display: block;
        margin-left: -15px;
        min-height: 600px;
        max-width: 800px;
      }
    }

    .steps {
      color: $primary-button-color;
      font-size: 14px;
    }
  }

  .half-width {
    @include half-width;
    position: relative;
    text-align: left;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      text-align: left;
      margin-bottom: 0;
      padding-right: 6rem;
      padding-top: 60px;
      width: 40%;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin: 5px 0;
        padding: 0;
        text-align: left;
        display: flex;
      }

      span {
        width: 40px;

        @include media-breakpoint-up(md) {
          width: 45px;
        }

        color: #ba2636;
        display: inline-block;
      }
    }
  }

  input {
    background: $white;
    height: 60px;
    @include border-radius(6px);
    min-width: 180px;
    display: block;

    @include media-breakpoint-up(lg) {
      min-width: 280px;
    }

    padding: 10px 15px;
    @include padding(10px, 10px, 15px, 15px);
    margin-right: 15px;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(34, 34, 34, 0.15);
    border-radius: 6px;
    outline: none;
  }

  .button-primary {
    border: none;

    @include media-breakpoint-up(lg) {
      min-width: 140px;
    }
  }

  .steps {
    font-weight: 700;
    color: #5c5ce5;
    font-size: 14px;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 28px;
    color: #151147;
  }
}

.batch-img {
  position: relative;
  margin-bottom: 40px;

  &::before {
    content: '';
    height: 170px;
    width: 170px;
    display: block;
    position: absolute;
    right: -50px;
    background-size: 100%;

    @media (max-width: 1700px) {
      right: -35%;
    }

    @media (max-width: 1500px) {
      right: -40%;
    }

    @media (max-width: 1250px) {
      right: -50%;
    }

    @media (max-width: 991px) {
      right: -80%;
    }

    @media (max-width: 768px) {
      right: 40%;
      top: 60%;
      height: 100px;
      width: 100px;
    }

    @media (max-width: 680px) {
      right: 0;
    }
  }
}
