@import '../../../assets/scss/index';
#mind-rest {
  background: $banner-text;
  @include padding(10px, 40px, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(80px, 80px, 0, 0);
  }
  width: 100%;
  p {
    font-size: 14pt;
    color: #4f4f4f;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
    }
  }
  .search-home {
    @include flex-main;
  }
  .row {
    @include flex-direction(column);
    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }
  .half-width-img {
    @include half-width;
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
      padding-right: 4rem;
    }
    img {
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
    }
    .steps {
      color: $primary-button-color;
      font-size: 14px;
    }
  }
  .half-width {
    @include half-width;
    position: relative;
    text-align: left;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      text-align: left;
      margin-bottom: 0;
      padding-left: 1rem;
    }
  }
  .steps {
    font-weight: 700;
    color: #5c5ce5;
    font-size: 14px;
  }
  .stepcontant {
    font-size: 14pt;
  }
  .question {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}
