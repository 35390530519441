#riskRp {
  @include padding(50px, 50px, 0, 0);
  .row {
    flex-wrap: wrap !important;
  }
  h1 {
    font-size: 28px;
  }
  button {
    background: #fafafa;
    border: 1px solid #dbdbdb;
    margin-top: 20px;
    width: 100%;
    display: flex;
    text-align: left;
    img {
      margin-right: 10px;
      @include media-breakpoint-up(lg) {
        margin-right: 20px;
      }
    }
  }
  .half-width {
    @include make-col-ready();
    width: 50%;
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(3);
    }

    div {
      background: #ffffff;
      border: 0.5px solid #e6e6e6;
      box-sizing: border-box;
      border-top: 6px solid #e8e8ff;
      border-radius: 6px;
      padding: 20px 8px;
      margin-top: 20px;
      p {
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }

  .risk-report-cards {
    width: 50%;
    @include media-breakpoint-up(md) {
      width: 33%;
    }

    div {
      background: #ffffff;
      border: 0.5px solid #e6e6e6;
      box-sizing: border-box;
      border-top: 6px solid #e8e8ff;
      border-radius: 6px;
      padding: 20px 8px;
      margin-top: 20px;
      p {
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }
}

.innerwrap {
  max-width: 1200px;
  margin: 0 auto;
}

