@import '../../assets/scss/index.scss';

#Version {
    background: url('../../assets/images/abBg.png') no-repeat;
    background-size: cover;
    background-position: center;
  
    @include padding(40px, 80px, 0, 0);
    @include media-breakpoint-up(md) {
      @include padding(80px, 80px, 0, 0);
      min-height:74vh;
      background-attachment: fixed;
    }
}