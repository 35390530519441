@import "../../../../assets/scss/index";
.button-primary {
    background: $primary-button-color;
    color: $white;
    border: none;

    &:hover {
        background-color: $primary-button-hover-color;
    }
}

.button-secondary {
    border: 1px solid $white;
    color: $white;
    background: none;

    &:hover {
        background-color: $secondary-button-hover-color;
    }
}

.button-secondary-body {
    border: 1px solid $primary-button-color;
    color: $primary-button-color;
    background: none;
}

button {
    text-align: center;
    display: inline-block;
    @include padding(15px, 15px, 20px, 20px);
    @include border-radius(6px);
    @include media-breakpoint-up(lg) {
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus {
        background-color: $disabled-button;
        color: $disabled-button-color
    }
}

.btn-primary:hover {
    background-color: $primary-button-hover-color;
}

.btn-secondary:hover {
    background-color: $secondary-button-hover-color;
}
