@import '../../../assets/scss/index';
.white-bg {
  background: $white;
  transform: skewY(-4deg);
  height: 150px;
  width: 100%;
  display: none;
  margin-top: 15px;

  @include media-breakpoint-up(md) {
    margin-top: -15px;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
    display: block;
  }
  position: relative;
}

#risk-home {
  background: $backgound-title;
  background-position: bottom left;
  @include padding(140px, 0, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(100px, 0, 0, 0);
  }
  width: 100%;
  p {
    font-size: 14pt;
    color: #4f4f4f;
    text-align: center;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
      font-weight: 400;
      text-align: left;
    }
  }
  a {
    color: #5c5ce5;
    text-decoration: none;
  }
  .search-home {
    @include flex-main;
  }
  .row {
    @include flex-direction(column-reverse);
    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }
  .half-width-img {
    @include half-width;
    text-align: center;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      text-align: right;
      margin-bottom: 0;
    }
    img {
      margin: 0 auto;
      width: 100%;
      max-width: 565px;
    }
  }
  .half-width {
    @include half-width;
    position: relative;
    text-align: left;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }
  button {
    margin-top: 32px;
    font-weight: 600;
    font-size: 14pt;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  h2 {
    font-size: 28px;
    color: $h-color;
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }
  .mobilewhite-bg {
    background: #fafafa;
    transform: skewY(-4deg);
    height: 60px;
    width: 100%;
    display: block;
    margin-top: -55px;
    position: relative;
    box-shadow: 0px -2.89263px 2.89263px rgba(0, 0, 0, 0.05);
    display: none;
    @include media-breakpoint-down(md) {
      width: 100%;
      display: block;
    }
  }
}
