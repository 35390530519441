@import '../../../assets/scss/index';
#testimonials-property {
  background: $banner-bg;
  @include padding(20px, 20px, 0, 0);
  @include media-breakpoint-up(md) {
    background: url('../../../assets/images/testimonials.png') right top
      no-repeat $banner-bg;
    @include padding(50px, 100px, 0, 0);
  }
  width: 100%;
  p {
    font-size: 28px;
    color: $white;
    @include media-breakpoint-up(lg) {
      font-size: 1.875rem;
    }
  }
  .half-width {
    @include half-width;
    position: relative;
    text-align: left;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      text-align: left;
      margin-bottom: 0;
      padding-right: 3rem;
      padding-top: 80px;
    }
    h3 {
      color: $white;
      font-size: 14pt;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .name {
      color: $white;
      font-size: 14pt;
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        margin-bottom: 0px;
      }
    }
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-dots {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    .slick-active {
      button {
        background: #fff;
      }
    }
    li {
      list-style-type: none;
      button {
        min-width: 15px;
        width: 15px;
        height: 15px;
        border-radius: 40px;
        border: none;
        background: #aaaaaa;
        padding: 0;
        font-size: 0;
        margin-right: 10px;
      }
    }
  }
}
