@import "../../../../assets/scss/index.scss";

.tab-item {
    background: $navbar;
    margin: 10px;
    height: 62px;
    position: relative;
    display: flex;
    flex-direction: row;

    .tab-title-selected {
        display: flex;
        align-items: center;
        color: $white;
        background-color: $button;
        padding: 2px 4px;
        text-decoration: none;
        margin: 4px;
        height: 45px;
    }
    .tab-title-unselected {
        display: flex;
        align-items: center;
        color: $white;
        padding: 4px 20px;
        text-decoration: none;
        margin: 4px;
        height: 45px;
    }
}

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}
