.loading {
  text-align: center;
  h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 120%;
    color: #151147;
  }
  h3 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 28px;

    color: #151147;
  }
  p {
    margin-top: 30px;
    max-width: 440px;
    font-size: 14pt;
    color: #4f4f4f;
  }
}

.loadingspinner {
  pointer-events: none;
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid transparent;
  border-color: #eee;
  border-top-color: #5c5ce5;
  border-left-color: #5c5ce5;
  border-right-color: #5c5ce5;
  border-radius: 50%;
  animation: loadingspin 1s linear infinite;
}
.reportloder {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes loadingspin {
  100% {
    transform: rotate(360deg);
  }
}
