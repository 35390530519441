@import '../../assets/scss/index.scss';

.riskdefs {
  .riskdefinitiontopbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 45px;
  }

  .riskdefinitionlow {
    background-color: #a4dda9;
    width: 100%;
    max-width: 25%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;

    p {
      color: #238258;
      text-align: center;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .riskdefinitionmedium {
    background-color: #ffd479;
    width: 100%;
    max-width: 25%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #ab5e23;
      text-align: center;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .riskdefinitionmedhigh {
    background-color: #fdc390;
    width: 100%;
    max-width: 25%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #b4523b;
      text-align: center;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .riskdefinitionheigh {
    background-color: #e7a4ab;
    width: 100%;
    max-width: 25%;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #ba2636;
      text-align: center;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .definitionsection {
    display: flex;
    margin-top: 48px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .definitioncirclelow {
      background: #a4dda9;

      p {
        color: #238258;
      }
    }

    .definitioncirclemedium {
      background: #ffd479;

      p {
        color: #ab5e23;
      }
    }

    .definitioncirclemediumhigh {
      background: #fdc390;

      p {
        color: #b4523b;
      }
    }

    .definitioncircleheigh {
      background: #e7a4ab;

      p {
        color: #ba2636;
      }
    }

    .definitioncircle {
      width: 156px;
      height: 156px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 80px;
        height: 80px;
      }

      p {
        font-weight: 700;
        font-size: 64px;

        margin-bottom: 0;

        @media (max-width: 768px) {
          font-size: 48px;
        }
      }
    }

    .definitionrightsection {
      margin-left: 2rem;
      margin-top: 1rem;

      @media (max-width: 768px) {
        margin-left: 1rem;
        margin-top: 0.25rem;
      }

      h2 {
        font-weight: 700;
        font-size: 24px;
        color: #151147;
      }

      p {
        color: #4f4f4f;
        font-weight: 400;
        font-size: 14pt;
        margin-bottom: 0;
      }
    }
  }
}
