@import '../../../assets/scss/index';
#riskpg {
  break-inside: avoid;

  h2 {
    font-weight: bold;
    font-size: 24px;
  }
  button {
    background: #fafafa;
    border: 1px solid #dbdbdb;
    margin-top: 20px;
    width: 100%;
    display: flex;
    text-align: left;
    img {
      margin-right: 10px;
      @include media-breakpoint-up(lg) {
        margin-right: 20px;
      }
    }
  }
  .half-width {
    @include make-col-ready();
    width: 50%;
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(3);
    }

    div {
      background: #ffffff;
      border: 0.5px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 8px 15px;
      height: 100%;

      p {
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }

}

.row {
  @media (max-width: 580px) {
    overflow-x: auto !important;
  }
}

.half-width {
  span {
    font-weight: 500;
    font-size: 14px;
  }

  .highrisk {
    border-top: 6px solid #e7a4ab !important;
    span {
      color: #ba2636;
    }
  }

  .lowrisk {
    border-top: 6px solid #a4dda9 !important;
    span {
      color: #238258;
    }
  }

  .highmedrisk {
    border-top: 6px solid #fdc390 !important;
    span {
      color: #b4523b;
    }
  }

  .mediumrisk {
    border-top: 6px solid #ffd479 !important;
    span {
      color: #ab5e23;
    }
  }

  .lowmedrisk {
    border-top: 6px solid #d4e882 !important;
    span {
      color: #4e7e37;
    }
  }

  .notAssessedRisk {
    border-top: 6px solid #dbdbdb !important;
    span {
      color: #898989;
    }
  }

}


.innerwrap {
  max-width: 1200px;
  margin: 0 auto;
}
