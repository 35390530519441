#search-outer {
  width: -webkit-fill-available;
  background-color: white;
  position: absolute;
  z-index: 2000 !important;
  padding: 0;
  top: -1px;
  //   height: auto;
  .container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 12.5rem;
    input {
      outline: none;
      letter-spacing: 1px;
      font-family: Inter;
      width: 85vw;
      font-size: 32px;
      font-weight: 400;
      line-height: 1;
      height: 60px !important;
      border: none;
      border-bottom: 2px solid #5c5ce5;
      &::placeholder {
        color: black;
      }
    }
    .nav-search-btn {
      border-radius: 50%;
      padding: 1rem;
      background-color: #e8e8e8;
      color: inherit;
      border: none;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      width: none;
    }
  }
}

.global-search {
  height: 0px;
  -webkit-transition: height 0.5s linear;
  -moz-transition: height 0.5s linear;
  -ms-transition: height 0.5s linear;
  -o-transition: height 0.5s linear;
  transition: height 0.2s linear;
  * {
    display: none;
  }

  &.show {
    height: 12.5rem;
    -webkit-transition: height 0.5s linear;
    -moz-transition: height 0.5s linear;
    -ms-transition: height 0.5s linear;
    -o-transition: height 0.5s linear;
    transition: height 0.2s linear;
    * {
      display: initial;
    }
  }
}
