@import '../../assets/scss/index.scss';

#Terms_condition {
  background: url('../../assets/images/abBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  @include padding(40px, 0, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(80px, 0, 0, 0);
    background-attachment: fixed;
  }
  p {
    font-size: 14pt;
    color: #4f4f4f;
    font-weight: 400;
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
  .termspage {
    background: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    padding: 32px;
    max-width: 800px;
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
      padding: 32px 15px;
    }
  }
  .termpera {
    margin-bottom: 25px;
  }
  h1 {
    font-size: 28px;
    margin-bottom: 15px;
  }
}
