@import '../../../../../assets/scss/index.scss';

#tab-content-wrapper {
  width: 100%;
  padding: 39px 8px;
  .tags-main {
    .tags-input {
      border-color: grey;
      height: 40px;
      width: 100%;
      margin-top: 8px;
    }
    .tags-wrapper {
      display: flex;
      flex-direction: column;
      width: 50%;

      li {
        display: flex;
        margin-top: 2px;
        padding: 8px;
        border-radius: 8px;
        align-items: center;

        .tag-close-icon {
          padding-right: 8px;
          font-size: 22px;
          cursor: pointer;
        }
      }
    }
    .risk-taking-title {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14pt;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #151147;
      margin-top: 1rem;
    }
    .hazard-checkbox {
      min-width: 62px;
    }
  }

  .title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .heading-title {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 120%;
    }
    .version-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .version-first {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .add-title {
          padding-right: 12px;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14pt;
          line-height: 120%;
        }
        #dropdown-basic {
          background: #ffffff;
          border: 1px solid #babaf5;
          box-sizing: border-box;
          border-radius: 4px;
          width: 141px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 45px;
        }
        .dropdown-item-wrapper {
          background-color: white;
          display: flex;
          flex-direction: column;
          width: 141px;
          justify-content: flex-start;
          z-index: 9999999;

          .dropdown-item {
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            text-decoration: none;
            padding: 4px;
            border-bottom: none;
          }
        }
      }

      .logs-button {
        display: flex;
        justify-content: space-evenly;
        padding: 12px;
        align-items: center;

        background-color: $white;
        border: 1px solid #babaf5;
        box-sizing: border-box;
        border-radius: 4px;
        height: 45px;
        width: 127px;
        cursor: pointer;
        span {
          padding-left: 4px;
          color: #6b6bff;
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14pt;
          line-height: 100%;
        }
      }
    }
  }
  .risk-tab-wrapper {
    padding: 36px 0 0 0;
    .admintab {
      background: #f7f7f7;
    }
    ul {
      display: flex;
      flex-direction: row;
      padding: 0;
      #risk-tab {
        list-style-type: none;
        flex-direction: row;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14pt;
        line-height: 120%;
        padding: 15px 10px;
        text-align: center;
        color: #2f2f60;
        cursor: pointer;
      }
    }
    .ql-editor {
      ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        li {
          padding: 2px 10px;
          text-align: left;
        }
      }
    }
  }
  .risk-scale {
    width: 100%;
    display: flex;
    align-items: center;
    ul {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      li {
        width: 55px;
        height: 60px;
        padding: 12px;
        margin: 8px;
        background: #f9f9f9;
        border: 1px solid #21ad2d;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 100%;
        /* identical to box height, or 26px */

        display: flex;
        align-items: center;
        justify-content: center;

        color: #21ad2d;
      }
    }
  }
  .risk-taking-wrapper {
    margin: 15px 0;
    margin-top: 30px;
    .risk-taking-title {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14pt;
      line-height: 150%;
      /* or 27px */

      display: flex;
      align-items: center;

      /* Text Default */

      color: #151147;
    }
    .risk-taking-answer {
      background: #ffffff;
      border: 1px solid #d5d5d5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 8px;
    }
  }
  .button-primary {
    height: 60px !important;
    width: 80px;
    background-color: rgba(194, 194, 255, 0.4);
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-left: auto;
    margin-right: 0px;
    color: #6b6bff;
    cursor: pointer;
    .button-text {
      padding-left: 4px;
    }
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .cross-icon {
    cursor: pointer;
  }

  span {
    color: $text-default;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
  }
}
.table-wrapper {
  width: 100%;
  margin-bottom: 20px;
  thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    th {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14pt;
      line-height: 120%;
      color: #000000;
      width: 100%;
      text-align: left;
      padding-bottom: 10px;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #ddddff;
      td {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 120%;
        width: 100%;
        color: #000000;
        padding: 6px 5px;
        margin: 10px 0;
      }
    }
  }
}

.risk-taking-wrapper {
  margin: 12px 0;
  .risk-taking-field {
    textarea {
      width: 100%;
      height: 80px;
      border: 1px solid #d5d5d5;
      padding: 8px;
    }
  }
  .risk-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 99;
  }
  .risk-taking-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    line-height: 150%;
    /* or 27px */

    display: flex;
    align-items: center;

    /* Text Default */

    color: #151147;
  }
  .risk-taking-answer {
    background: #ffffff;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px;
  }
}
#search-wrapper-main {
  .tab-list {
    margin-top: 22px;
    .tabs {
      margin: 2px;
      width: auto;
    }
  }
}
