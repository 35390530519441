@import '../../assets/scss/index';
.repor_page {
  .wrap {
    padding-top: 1rem !important;
  }
  @media (max-width: 768px) {
    overflow: hidden;
  }
  .half-width-right {
    @include make-col-ready();
    @include media-breakpoint-down(md) {
      padding: 0 10px;
    }
    @include media-breakpoint-up(lg) {
      @include make-col(9.8);
      padding: 20px;
    }
    @include media-breakpoint-up(xl) {
      @include make-col(9.8);
      padding: 20px;
    }
  }
  .side-nav {
    position: relative;
    @include make-col-ready();
    @include media-breakpoint-down(md) {
    }
    @include media-breakpoint-up(lg) {
      @include make-col(2.2);
      padding: 0;
    }
    @include media-breakpoint-up(xl) {
      @include make-col(2.2);
      padding: 0;
    }
  }
  .half-width {
    padding: 20px;
    position: relative;
    @include make-col-ready();
    @include media-breakpoint-down(md) {
    }
    @include media-breakpoint-up(lg) {
      @include make-col(2.2);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(2.2);
    }
  }
}
.md-helf-width {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
.report-icon {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 15px;

  P {
    font-weight: 600;
    font-size: 14pt;
    line-height: 100%;
    color: #151147;
    margin-bottom: 0;
    opacity: 0.7;
    img {
      margin-right: 12px;
    }
  }
}
.report-icon.active {
  background: #fff;
  border: 0.5px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);

  p {
    color: black;
    opacity: 10;
  }
  img {
    filter: invert(24%) sepia(89%) saturate(1400%) hue-rotate(224deg)
      brightness(91%) contrast(89%);
  }
}

.stickysidebar {
  padding-top: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  margin-bottom: 1rem;
  a {
    text-decoration: none;
  }
}
.printabel-component {
  display: none;
}
