@import '../../../assets/scss/index';
.rp-banner {
  img {
    width: 100%;
  }
}
.rp-btnsection {
  .row {
    @include flex-direction(column);
    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }
  .rightcontant {
    padding: 20px !important;
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(12);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(7);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(7);
    }
  }

  .leftcontant {
    padding: 0 20px 20px;
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(12);
      padding: 20px;
    }
    @include media-breakpoint-up(lg) {
      @include make-col(5);
      padding: 20px;
    }
    @include media-breakpoint-up(xl) {
      @include make-col(5);
      padding: 20px;
    }
  }
  h2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    margin-bottom: 0;
    @include media-breakpoint-down(md) {
      font-size: 32px;
    }
  }
  p {
    font-size: 14pt;
    line-height: 150%;
  }
  .reportbtn {
    text-align: right;
  }
  .printbtn {
    min-width: 40px;
    margin-left: 10px;
    border: 1px solid #343473;
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .button-secondary.btn.btn-primary {
    border: 1px solid #343473;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14pt;
    color: #343473;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .mbmsg {
    text-align: left;
    background: #efeffd;
    border-radius: 6px;
    padding: 10px 20px;
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {
      display: none;
    }
    p {
      font-size: 13px;
      line-height: 150%;
      color: #151147;
      margin-bottom: 0;
      display: flex;
      align-items: flex-start;
      img {
        margin-right: 5px;
        margin-top: 4px;
      }
    }
  }
}
