@import '../../assets/scss/index';
#forget_page {
  padding: 120px 0;
  background: url('../../assets/images/abBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 250px);
  .innerwrap {
    max-width: 400px;
    margin: 0 auto;
  }
  .auth0-lock.auth0-lock .auth0-lock-center {
    padding: 0;
  }
  .auth0-lock.auth0-lock .auth0-lock-form {
    padding: 0;
  }
  .auth0-lock.auth0-lock
    .auth0-lock-social-button.auth0-lock-social-big-button:nth-child(2) {
    background: #3377ea;
  }
  .auth0-lock-alternative {
    margin-top: 30px !important;
    margin-bottom: 40px !important;
  }
  .auth0-lock.auth0-lock
    .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
    padding-left: 0;
    border: none;
    svg {
      display: none;
    }
  }
  .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
    height: auto;
  }
  .auth0-lock-form div div:nth-child(3) {
    display: flex;
    flex-direction: column;
  }
  .auth0-lock-social-button.auth0-lock-social-big-button {
    height: 48px;
    border: 1px solid #828282;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .auth0-lock-input-block.auth0-lock-input-email {
    order: 2;
  }
  .auth0-lock-input-block.auth0-lock-input-show-password {
    order: 3;
  }
  .auth0-lock-input-block.auth0-lock-input-full_name {
    order: 1;
  }
  .auth0-lock-social-button-icon {
    height: 48px !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-header-welcome {
    background: #2f2f60;
    color: #fff !important;
  }
  .auth0-lock-input {
    min-width: 100% !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-header-bg {
    background: #2f2f60 !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-input-wrap {
    background: transparent !important;
    border: none !important;
  }
  .auth0-lock-terms {
    display: none !important;
  }
  .auth0-lock-form p {
    display: none !important;
  }
  h2 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 8px;
  }
  p {
    font-size: 14pt;
    color: #4f4f4f;
    max-width: 300px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 32px;
  }
  button {
    width: 100%;
    font-weight: 600;
    font-size: 14pt;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: #5c5ce5 !important;
    border-radius: 6px;
    margin-top: 25px;
    img {
      margin-right: 8px;
    }
  }
  input {
    width: 100%;
    min-width: 100%;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    height: 48px;
  }

  .loginfeild {
    .loginemail {
      margin-bottom: 25px;
    }
    .loginpass {
      margin-bottom: 32px;
    }
    label {
      font-weight: 600;
      font-size: 14pt;
      margin-bottom: 8px;
    }
  }
  .loginbottom {
    margin-top: 40px;
    text-align: center;
    a {
      font-weight: 500;
      font-size: 14pt;
      margin-bottom: 20px;
      display: inline-block;
      color: #505de5;
    }
    p {
      font-weight: normal;
      font-size: 14pt;
    }
  }
}
