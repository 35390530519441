@import '../../../../../assets/scss/index.scss';
.risk-tab-container {
  width: 100%;
}
.risk-tab-item {
  // background: #f7f7f7;
  margin: 0px;
  height: 62px;
  position: relative;
  display: flex;
  flex-direction: row;
  background: #f7f7f7;
  padding-right: 24px;

  .risk-tab-title-selected {
    display: flex;
    align-items: center;
    color: #2f2f60;
    padding: 4px 20px;
    text-decoration: none;
    margin: 8px;
    height: 45px;
    font-weight: 600;
  }
  .risk-tab-title-unselected {
    display: flex;
    align-items: center;
    color: #2f2f60;
    padding: 4px 20px;
    text-decoration: none;
    margin: 8px;
    height: 45px;
    font-weight: normal;
    border-bottom: none;
  }
}
.content-container {
  width: 100%;
}

.risk-tab-wrapper {
  padding: 12px;
  .quill {
    resize: vertical;
    overflow-y: auto;
    height: auto;
    min-height: 60px;
  }
  .risk-scale {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 25px;
      height: 25px;
      margin-right: 18px;
    }
    .risk-scale-input {
      display: flex;
    }

    .logs-button {
      display: flex;
      justify-content: center;
      padding: 12px;
      align-items: center;

      background-color: $white;
      border: 1px solid #babaf5;
      box-sizing: border-box;
      border-radius: 4px;
      height: 45px;
      width: 127px;
      cursor: pointer;
      span {
        color: #6b6bff;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14pt;
        line-height: 100%;
      }
    }

    .min-text {
      height: 60px;
      width: 60px;
      min-width: 55px;
      padding: 12px;
      margin: 8px;
      background: #f9f9f9;
      border: 1px solid #21ad2d;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 100%;
      text-align: center;
      /* identical to box height, or 26px */

      display: flex;
      align-items: center;

      color: #21ad2d;
    }
  }
  .risk-taking-wrapper {
    margin: 15px 0;
    margin-top: 30px;
    .risk-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .risk-taking-title {
      font-style: normal;
      font-weight: 600;
      font-size: 14pt;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #151147;
    }
    .risk-taking-answer {
      background: #ffffff;
      border: 1px solid #d5d5d5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 8px;
    }
  }
  .button-primary {
    height: 60px !important;
    width: 80px;
    background-color: rgba(194, 194, 255, 0.4);
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-left: auto;
    margin-right: 0px;
    color: #6b6bff;
    .button-text {
      padding-left: 4px;
    }
  }
}

.risk-tab-wrapper {
  padding: 12px;
}
.risktablemodal {
  .modal-body {
    padding: 20px;
    background: #ffffff;
    border: 1px solid #383867;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 25px;
  }
}

.tablecomment {
  background: #f1f1f1;
  margin: 15px 0 !important;
}
