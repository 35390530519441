#riskpreview {
  @include padding(50px, 50px, 0, 0);

  .wrap {
    box-shadow: 0px 0px 16px rgba(47, 47, 96, 0.2);
    padding: 20px;
  }
  .row {
    @include flex-direction(column);
    @include media-breakpoint-up(md) {
      @include flex-direction(row);
    }
  }
  .half-width {
    padding: 20px;
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(6);
    }
    div {
      display: flex;
      align-items: center;
      h3 {
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 0;
        margin-left: 25px;
      }
    }
    .reportbtn {
      justify-content: center;
      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
      button {
        max-width: 400px;
        width: 100%;
      }
    }
  }
  .report-priview {
    border-top: 1px solid #dbdbdb;
    padding: 40px 20px;
    padding-bottom: 0;
    h3,
    .rep-prev {
      color: #5c5ce5;
      font-weight: 600;
      font-size: 14pt;
    }
    h2 {
      font-weight: 600;
      font-size: 48px;
      color: #151147;
      margin-top: 10px;
      @include media-breakpoint-down(md) {
        font-size: 32px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
  }
  .actiond {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .actionm {
    display: none;
    max-width: 250px !important;
    @include media-breakpoint-down(md) {
      display: block;
      max-width: 250px !important;
      margin-top: -50px !important;
    }
  }
  .flooding {
    margin-top: -4%;
    .flodingMain {
      display: flex;
      justify-content: space-between;
      text-align: center;
      align-items: stretch;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 25%;
        width: 100%;
        text-align: center;
        p {
          margin-bottom: 0;
          padding: 6px;
          font-weight: 500;
          font-size: 14px;
          @media (max-width: 520px) {
            font-size: 12px;
            padding: 6px 2px;
          }
          @media (max-width: 440px) {
            font-size: 9px;
          }
        }
      }
      .not_assessed {
        background: #dbdbdb;
        color: #828282;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }
      .lowf {
        background: #a4dda9;
        opacity: 0.8;
        color: #238258;
      }
      .lowMf {
        background: #d4e882;
        opacity: 0.8;
        color: #4e7e37;
      }
      .MedHf {
        background: #fdc390;
        color: #b4523b;
      }
      .medlowf {
        background: #e7a4ab;
        opacity: 0.8;
        color: #ba2636;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
  }
  .acmobile {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .f-tooltip {
    background: #ffffff;
    border: 1px solid #5c5ce5;
    box-sizing: border-box;
    box-shadow: 0px 4px 16px rgba(34, 34, 34, 0.15);
    border-radius: 6px;
    max-width: 520px;
    margin-left: 35%;
    position: relative;
    margin-bottom: 18px;
    height: 58px;
    @include media-breakpoint-down(md) {
      margin-left: 1%;
      height: auto;
      padding: 5px 0;
    }
    &::before {
      content: '';
      background-image: url(../images/arrow1.svg);
      position: absolute;
      top: 100%;
      left: 15px;
      height: 20px;
      width: 10px;
      display: block;
      background-repeat: no-repeat;
    }
    ul {
      @include media-breakpoint-down(md) {
        margin: 0;
      }
    }

    li {
      font-size: 14pt;
      font-family: Inter;
      list-style: none;
      @include media-breakpoint-down(md) {
        font-size: 14pt;
      }
      &::before {
        content: '•';
        color: #5c5ce5;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -0.75em;
        font-size: 30px;
        position: absolute;
        top: 6px;
      }
    }
  }
  .floodingrow {
    margin: 24px 10px;
    img {
      width: 100%;
      @include media-breakpoint-down(md) {
        height: 35px;
      }
    }
  }
  .riskRow {
    margin-top: 50px;
    img {
      max-width: 820px;
      width: 100%;
      margin-top: -20px;
    }
  }
  .mapRow {
    position: relative;
    .f-tooltip {
      max-width: 330px;
      margin-bottom: 0px;
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 90px;
        left: 10%;
        width: 75%;
      }
      &::before {
        background-image: url(../images/arrow2.svg);
        height: 30px;
      }
    }
    img {
      width: 100%;
      @include media-breakpoint-down(md) {
        height: 190px;
      }
    }
    li {
      &::before {
        @include media-breakpoint-down(md) {
          top: -7px;
        }
      }
    }
  }
  .takeaction {
    margin-top: 40px;
    margin-bottom: 60px;
    .f-tooltip {
      max-width: 218px;
      margin-left: 0;
      position: relative;
      margin-bottom: -33px;
      &::before {
        display: none;
      }
      li {
        &::before {
          @include media-breakpoint-down(md) {
            top: -7px;
          }
        }
      }
    }
    img {
      width: 100%;
      max-width: 1000px;
    }
  }
  .riskdesktop {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
    h3 {
      font-size: 28px;
    }
  }
  .riskmobile {
    display: none;
    position: relative;
    @include media-breakpoint-down(md) {
      display: block;
      position: relative;
    }
    h2 {
      margin-bottom: 30px;
      margin-top: 20px;
    }
    .f-tooltip {
      position: absolute;
      top: 32%;
      width: 80%;
      left: 3%;
    }
    img {
      margin-bottom: 20px;
    }
  }
  .view-report-header {
    margin-bottom: 2.5rem;
  }
}
