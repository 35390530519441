@import '../../assets/scss/index';

#divToPrint {
    #riskpg {
        .half-width {
            width: 20%;
          }
      
          .wrap {
            max-width: inherit;
          }
    }

}