/*!
 * Bootstrap v5.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');

// import "../node_modules/slick-carousel/slick/slick.scss";
// import "../node_modules/slick-carousel/slick/slick-theme.scss";
// scss-docs-start import-stack
//Configuration
@import "functions";
@import "variables";
@import "mixins";
//@import "utilities";
// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "grid";
@import "transitions";
@import "theme";
@import "reports-banner";
@import "risk-report";
@import "reports-banner";
@import "risk-report";
@import "_risk-preview";
// //@import "nav";
// //@import "navbar";

@import "slick-carousel/slick/slick.scss";
@import "react-accessible-accordion/dist/fancy-example.css";

.inputfocus {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.error {
    color: #9b0303 !important
}

.success {
    color: green !important
}

.minmumHeightBox {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 258px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Lato, Arial, Helvetica, sans-serif;
}

p {
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-weight: 300;
}