@import '../../assets/scss/index';
#pageNotFound {
  background: url('../../assets/images/abBg.png') no-repeat;
  background-size: cover;
  background-position: center;

  @include padding(40px, 80px, 0, 0);
  @include media-breakpoint-up(md) {
    @include padding(80px, 0, 0, 0);
    height: 75vh;
    background-attachment: fixed;
  }

  .innerwrap {
    max-width: 480px;
    margin: 60px auto !important;

    p {
      text-align: center;
      font-size: 14pt;
      color: $base-color;
    }

    h1 {
      text-align: center;
      margin-bottom: 2.5rem;
    }

    h2 {
      text-align: center;
      margin-bottom: 2.5rem;
    }
  }
}
