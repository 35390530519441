@import '../../../assets/scss/index.scss';

#search-wrapper-main {
  background-size: cover;
  background-position: center;
  min-height: 90vh;

  .half-width-right {
    @include make-col-ready();

    @include media-breakpoint-down(md) {
      padding: 0 10px;
    }

    @include media-breakpoint-up(md) {
      @include make-col(9.8);
      padding: 20px;
    }
  }

  .md-helf-width {
    padding: 20px;
    position: relative;
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(2.2);
    }
  }

  @include padding(40px, 0, 0, 0);

  @include media-breakpoint-up(md) {
    @include padding(12px, 40px, 0, 0);
    background-attachment: fixed;
  }

  p {
    font-size: 14pt;
    color: #4f4f4f;
    font-weight: 400;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      font-size: 14pt;
      color: #4f4f4f;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .search-page {
    max-width: auto;
    position: relative;
    
    @include media-breakpoint-down(lg) {
      padding: 32px 15px;
    }

    .button-primary {
      height: 50px !important;
      width: 150px;
      background-color: rgba(194, 194, 255, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
      margin-left: auto;
      margin-right: 0px;
      color: #6b6bff;
      border-radius: 8px;
      cursor: pointer;

      .button-text {
        padding-left: 4px;
      }
    }
  }

  .termpera {
    margin-bottom: 25px;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
}

.map-loader {
  text-align: center;
}

.ql-snow .ql-tooltip {
  margin: auto;
  left: 0 !important;
  right: 0;
  width: fit-content;
}

#search-wrapper-main {
  .menu-item {
    label {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }
  .menu-item #dropdown-basic {
    width: 200px !important;
    font-size: 14px;
    @include media-breakpoint-down(xl) {
      width: 100% !important;
      margin-bottom: 1rem;
    }
  }
  .tab-container-list {
    .tabs {
      margin-bottom: 10px !important;
      justify-content: flex-start !important;
    }

    .tab-list {
      margin-top: -20px !important;
    }
  }

  .tab-container {
    .tabs {
      color: #000;
    }

    .active-tabs {
      background: #fff;
      border: 0.5px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 6px;
      box-shadow: 0px 0px 16px #0000001a;
    }

    .tab-list {
      display: block;
      background-color: #fff;
    }
  }
}
