@import '../../assets/scss/index';
#settingpage {
  padding: 135px 0;
  label {
    font-weight: 600;
    font-size: 14pt;
    margin-bottom: 8px;
  }
  .innerwrap {
    max-width: 400px;
    margin: 0 auto;
  }
  h1 {
    margin-bottom: 30px;
  }
  input {
    width: 100%;
    min-width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    height: 48px;
    border: 1px solid #828282;
  }
  button {
    width: 100%;
    font-weight: 600;
    font-size: 14pt;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: #5c5ce5 !important;
    border-radius: 6px;
    margin-top: 25px;
    color: #fff;
    border: none;
  }
  .settingnewpws {
    margin-top: 24px;
  }
  .settingtop {
    button {
      // background: #d9d9d9 !important;
      border-radius: 6px;
      // color: #808080;
    }
  }
  .devider {
    height: 1px;
    background: #bdbdbd;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .settingpassword {
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    span {
      font-size: 13px;
      line-height: 150%;
      color: #4f4f4f;
    }
  }
}
