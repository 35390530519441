@import '../../assets/scss/index';

$footer-bg-color: rgb(52, 52, 115);
$footer-button-bg-color: #6c6c8d;
$footer-text-color: white;
$base-font-family: Arial, sans-serif;

.footer-container {
  background-image: url('../../assets/images/contours_footer.png');
  background-position: left top;
  background-repeat: no-repeat;
  background-color: $footer-bg-color;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    color: white;
    font-size: 16px;
    font-family: Inter;
  }

  .container {
    max-width: 1860px;
  }

  .footer-mission-col {
    .footer-mission-row {
      margin-bottom: 2rem;
    }
    .footer-logo-col {
      display: flex;
      align-items: center;
    }
    .footer-logo {
      padding-right: 1rem;
      .logo-img {
        width: 100%;
      }
    }

    .mission {
      padding-right: 3rem;
    }
  }

  .row-container {
    padding: calc(100vw * 0.02) 70px calc(100vw * 0.01);
  }

  .social-media,
  .links {
    h4 {
      font-size: 18px;
      color: #ffb966;
      margin-bottom: 1rem;
    }
  }

  .links {
    align-self: flex-start;
    justify-content: center;
    ul {
      padding-left: 1.2rem;
      margin: unset;
      li {
        a {
          color: $footer-text-color;
          text-decoration: none;
          margin-bottom: 5px;
          &:hover {
            color: #5c5ce5;
          }
        }
      }
    }
  }

  .col-detail {
    padding: 0 2rem;
  }
}

@include media-breakpoint-up(lg) {
  .footer-mission-col {
    border-right: 1px solid #727272;
  }

  .links-social-media-container {
    padding-left: 5rem;
  }
}

@include media-breakpoint-down(lg) {
  .footer-container {
    .row-container {
      padding: 1.5rem 2rem;
    }
  
    .col-detail {
      padding: 0;
    }

    .logo-img {
      width: 80%;
      margin-bottom: 2rem;
    }
  }


  .footer-mission-col {
    border-bottom: 1px solid #727272;
  }

  .links-social-media-col {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .links {
    margin-bottom: 2rem;
  }
}
